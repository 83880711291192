<template>
    <b-modal id="financialSourcePopup" ref="financialSourcePopupRef" size="xl" modal-class="financialSourcePopup" @hide="closeDialog" title="Financial Source" no-close-on-esc no-close-on-backdrop>
        <b-overlay :show="showOverlay" spinner-variant="primary" opacity="0.4">
            <div class="row mb-auto w-100 mx-0">
                <div class="col-12 px-0 text-left">
                    <div class="form-row">
                        <div class="col-3">
                            <div class="form-group">
                                <label for="title">Title</label>
                                <div class="required-field-block" :class="{ 'is-invalid': $v.financialPlan.title.$error }">
                                    <input type="text" class="form-control" v-model="financialPlan.title" id="title" :class="{ 'is-invalid': $v.financialPlan.title.$error }" placeholder="Enter Item Title">
                                    <div class="required-icon"></div>
                                </div>
                                <label v-if="$v.financialPlan.title.$error && !$v.financialPlan.title.minLength" class="text-danger">Title must have at least {{$v.financialPlan.title.$params.minLength.min}} letters.</label>
                                <label v-if="$v.financialPlan.title.$error && !$v.financialPlan.title.required" class="text-danger">Please enter Title</label>
                                <label v-if="$v.financialPlan.title.$error && !$v.financialPlan.title.maxLength" class="text-danger">Title must not exceed {{$v.financialPlan.title.$params.maxLength.max}} letters.</label>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="form-group">
                                <label>Finance Source</label>
                                <input type="text" v-model="financialPlan.financeSource" class="form-control" id="financeSource" maxlength="1000" >
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="form-group">
                                <label>Amount</label>
                                <VhextMoneyControl :value="Number(this.financialPlan.amount)" @money-input="moneyControlInput" />
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="form-group">
                                <label for="targetDate">Target Date</label>
                                <input type="date" class="form-control" v-model="financialPlan.targetDate" id="targetDate">
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="form-group">
                                <label for="accountablePersonId">Accountable Person</label>
                                <select v-model="financialPlan.accountableUser" class="form-control">
                                    <option :value="{id: '', text:''}">Select</option>
                                    <option v-for="(info, index) in users" :value="{id: info.id, text:info.value}">{{info.value}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12 text-right mt-2">
                            <div class="text-right">
                                <button type="submit" @click="addCostEstimationBulk" class="sbtn btn btn-primary btn-xs px-2 mr-1"><i v-if="!elements.editMode" class="fa fa-plus-circle pr-1"></i>{{ elements.editMode ? 'Update' : 'Add' }}</button>
                                <button type="submit" @click="clearData" class="sbtn btn btn-outline-primary btn-xs px-2"><i class="fa fa-times pr-1"></i>{{ elements.editMode ? 'Cancel' : 'Clear' }}</button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body p-0 px-0 my-3">
                        <b-overlay :show="tableBusy" opacity="0.4">
                            <b-table show-empty small :items="financialPlanItems" :fields="fields" foot-clone no-footer-sorting responsive="sm">
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>
                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="Edit" class="" @click="showEdit(data.item,data.index)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                    <a href="javascript:" title="Remove Item" class="" @click="removeFinancialPlan(data.item)"><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template  v-slot:foot()="data">
                                    <span></span>
                                </template>
                                <template v-slot:cell(amount)="data">
                                    <div class="text-right">{{ data.value }}</div>
                                </template>
                                <template v-slot:foot(accountablePerson)="data">
                                    <div class="text-right">
                                        <span>Total </span>
                                    </div>
                                </template>
                                <template v-slot:foot(amount)="data">
                                    <span class="float-right">{{ currencySymbol }} {{ totalExpenses.toFixed(2)}}</span>
                                </template>
                                <template v-slot:empty="">
                                    <div class="text-center">Add items to show</div>
                                </template>
                                <template v-slot:emptyfiltered="">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            <template #overlay>
                                <div class="text-center">
                                </div>
                            </template>
                        </b-overlay>
                    </div>
                </div>
            </div>
        </b-overlay>
        <template #modal-footer>
            <div class="w-100">
                <b-button class="float-right" variant="secondary" size="sm" @click="closeDialog">
                    Cancel
                </b-button>
                <b-button v-if="updateFromGrid" :disabled="showOverlay" variant="primary" size="sm" @click="saveCostEstimation" class="float-right mr-2">
                    Save
                </b-button>
                <b-button v-else variant="primary" :disabled="showOverlay" size="sm" @click="saveBulkData" class="float-right mr-2">
                    Ok
                </b-button>
            </div>
        </template>
    </b-modal>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import VhextMoneyControl from '@/components/usercontrols/VhextMoneyControl.vue';
export default {
    props: {
        erdId: Number,
        erdTitle: String,
    },
    components: {
        VhextMoneyControl
    },
    data() {
        return {
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            entityRecordMasterId: this.$route.params.ermId,
            entity: 'financial_plan',
            userId: localStorage.getItem("userId"),
            currencySymbol: localStorage.getItem("currency"),

            elements: {
                editMode: false,
            },
            editIndex: null,
            tableBusy: false,
            updateFromGrid: false,
            showOverlay: false,

            financialPlan: {
                id: '',
                financialPlanId: '',
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entity: 'financial_plan',
                entityRecordMasterId: this.entityRecordMasterId,
                budgetPlanId: this.erdId,
                title: this.erdTitle,
                financeSource: '',
                amount: null,
                targetDate: '',
                receivedDate: '',
                responsiblePerson: '',
                accountablePersonId: '',
                accountablePerson: '',
                accountableUser: {
                    id: '',
                    text: '',
                },
                budgetPlanTitle: '',
                preparedById: this.userId,
                remarks: '',
                statusId: '',
                createdDt: '',
                lastUpdatedDt: ''
            },

            budgetPlanData: {
                id: '',
                // estimatedCost: '',
                financialPlanDataList: []
            },

            users: [],
            financialPlanBulk: [],

            fields: [
                { key: 'index', label: 'SN' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'budgetPlanTitle', label: 'Budget Item', sortable: true, sortDirection: 'desc' },
                { key: 'financeSource', label: 'Finance Source', sortable: true, sortDirection: 'desc' },
                { key: 'targetDate', label: 'Target Date',formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'accountablePerson', label: 'Accountable', sortable: true, sortDirection: 'desc' },
                { key: 'amount', label: 'Amount', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],

            // costEstimations: [],
            // costEstimationFields: [
            //     { key: 'index', label: 'SN' },
            //     { key: 'title', label: 'Item Title' },
            //     { key: 'quantity', label: 'Quantity' },
            //     { key: 'uomType', label: 'Unit' },
            //     { key: 'unitCost', label: 'Unit Cost' },
            //     { key: 'cost', label: 'Total Cost' },
            //     { key: 'actions', label: 'Actions' }
            // ],
        }
    },
    validations: {
        financialPlan: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            }
        }
    },
    computed: {
        financialPlanItems() {
            return this.financialPlanBulk;
        },
        totalExpenses() {
            return this.financialPlanItems.reduce((accum, item) => {
                return accum + parseFloat(item.amount ? item.amount : 0)
            }, 0.00)
        }
    },
    mounted() {
        // this.getCostEstimation();
        this.lookupData();
    },
    methods: {
        getCostEstimation: function(updateFromGrid) {
            this.updateFromGrid = updateFromGrid;
            console.log(this.erdId,this.erdTitle);
            if (this.erdId && this.erdId !== null) {
                let data = {
                    entityRecordMasterId: this.entityRecordMasterId,
                    budgetPlanId: this.erdId
                }
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/finance/financialplan/list', data)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        this.financialPlanBulk = result.financialPlanList;
                    })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                            title: 'Error',
                            message: 'Error',
                            position: 'topRight'
                        });
                    })
            }
            else this.financialPlanBulk = [];
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entity: this.entity,
                entityRecordMasterId: this.entityRecordMasterId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/finance/financialplan/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.users = result.userLookup;
                })
        },
        saveCostEstimation: function() {
            this.budgetPlanData.financialPlanDataList = this.financialPlanBulk;
            this.budgetPlanData.id = this.erdId;
            // this.budgetPlanData.estimatedCost = this.totalExpenses;
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/finance/financialplan/bulk/modify', this.budgetPlanData)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refreshData');
                        this.clearData();
                        this.clearBudgetData();
                        this.closeDialog();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        addCostEstimationBulk: function() {
            console.log(this.financialPlan);
            this.$v.financialPlan.$touch();
            if (!this.$v.financialPlan.$invalid) {
                let data = {};
                data.id = this.financialPlan.id
                data.financialPlanId = this.financialPlan.financialPlanId
                data.title = this.financialPlan.title
                data.budgetPlanTitle = this.financialPlan.budgetPlanTitle
                data.amount = this.financialPlan.amount
                data.financeSource = this.financialPlan.financeSource
                data.targetDate = this.financialPlan.targetDate
                data.receivedDate = this.financialPlan.receivedDate
                data.responsiblePerson = this.financialPlan.responsiblePerson
                data.accountablePerson = this.financialPlan.accountableUser.text
                data.accountablePersonId = this.financialPlan.accountableUser.id;
                data.preparedById = this.financialPlan.preparedById
                data.remarks = this.financialPlan.remarks
                data.statusId = this.financialPlan.statusId
                data.vhextAccountId = this.vhextAccountId;
                data.projectId = this.projectId;
                data.entityRecordMasterId = this.entityRecordMasterId;
                data.budgetPlanId = this.erdId;
                
                if (this.elements.editMode) {                    
                    this.financialPlanBulk.splice(this.editIndex, 1);
                    this.financialPlanBulk.splice(this.editIndex, 0, data);
                    this.$nextTick(() => {
                        this.clearData();
                    })
                }
                else {
                    console.log(this.financialPlanBulk)
                    this.financialPlanBulk.push(data);
                    this.$nextTick(() => {
                        this.clearData();
                    })
                }
            }
        },
        saveBulkData: function() {
            this.$emit('saveFinancialPlanList',this.financialPlanBulk);
            this.$bvModal.hide('financialSourcePopup');
        },
        showEdit: function(data, index) {
            this.elements.editMode = true;
            this.tableBusy = true;
            this.editIndex = index;
            console.log(data,index);
            this.financialPlan.id = data.id;
            this.financialPlan.financialPlanId = data.financialPlanId;
            this.financialPlan.title = data.title;
            this.financialPlan.budgetPlanTitle = data.budgetPlanTitle;
            this.financialPlan.budgetPlanId = data.budgetPlanId;
            this.financialPlan.amount = data.amount;
            this.financialPlan.financeSource = data.financeSource;
            this.financialPlan.targetDate = data.targetDate;
            this.financialPlan.receivedDate = data.receivedDate;
            this.financialPlan.responsiblePerson = data.responsiblePerson;
            this.financialPlan.accountablePerson = data.accountablePersonId;
            this.financialPlan.accountablePersonId = data.accountablePersonId;
            this.financialPlan.accountableUser.id = data.accountablePersonId;
            this.financialPlan.accountableUser.text = data.accountablePerson;
            this.financialPlan.preparedById = data.preparedById;
            this.financialPlan.remarks = data.remarks;
            this.financialPlan.statusId = data.statusId;
        },
        clearData: function() {
            this.elements.editMode = false;
            this.tableBusy = false;
            this.editIndex = null;
            this.financialPlan.id = '';
            this.financialPlan.financialPlanId = '';
            this.financialPlan.title = '';
            this.financialPlan.budgetPlanTitle = '';
            this.financialPlan.amount = '';
            this.financialPlan.financeSource = '';
            this.financialPlan.targetDate = '';
            this.financialPlan.receivedDate = '';
            this.financialPlan.responsiblePerson = '';
            this.financialPlan.accountablePersonId = '';
            this.financialPlan.accountablePerson = '';
            this.financialPlan.accountableUser.id = '';
            this.financialPlan.accountableUser.text = '';
            this.financialPlan.preparedById = '';
            this.financialPlan.remarks = '';
            this.financialPlan.statusId = '';
            this.$v.financialPlan.$reset();
        },
        clearBudgetData() {
            this.budgetPlanData.financialPlanDataList = [];
            this.budgetPlanData.id = null;
            // this.budgetPlanData.estimatedCost = '';
            this.financialPlanBulk = [];
            this.updateFromGrid = false;
        },
        moneyControlInput: function(value) {
            this.financialPlan.amount = value;
        },
        removeFinancialPlan: function(data) {
            this.financialPlanBulk = this.financialPlanBulk.filter((financeData)=> financeData !== data );
            if (data==this.financialPlan) {
                this.tableBusy = true;
            }
        },
        // setTotalCost: function() {
        //     this.financialPlan.cost = this.financialPlan.quantity * this.financialPlan.unitCost;
        // },
        closeDialog() {
            if (this.updateFromGrid) {
                this.clearBudgetData();
            }
            this.clearData();
            this.$emit('closeDialog');
            this.$bvModal.hide('financialSourcePopup');
        },
    }
}
</script>