<template>
    <div>
        <div v-if="loading" id="loader"></div>
        <div v-if="!loading">
            <div v-if="meetingMinutes.length > 0" class="row">
                <div class="col-12 col-sm-12 px-0">
                    <div class="accordion" role="tablist">
                        <template v-for="meetingMinute in meetingMinutes">
                            <b-card no-body class="mb-1">
                                <b-card-body class="py-3 px-2">
                                    <div class="form-row">
                                        <div class="col-10 col-md-10">
                                            <div class="row">
                                                <div class="col-2 col-md-2 pr-0">
                                                    <label class="p-1 pt-2 pb-2 d-block title-block">Topic</label>
                                                </div>
                                                <div class="col-6 col-md-6 pl-0">
                                                    <label class="p-2 mb-0 d-block header-details font-weight-bold">
                                                        {{ meetingMinute.title }}
                                                        <span v-if="meetingMinute.actualHour || meetingMinute.actualMinute" class="float-right">
                                                            [ {{ formatDurationHM(meetingMinute.actualHour,meetingMinute.actualMinute) }} ]
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="meetingMinute.status" class="col-2 col-md-2">
                                            <h6 class="m-0 text-center">
                                                <label class="badge badge-secondary" title="Status">{{ meetingMinute.status }}</label>
                                            </h6>
                                        </div>
                                        <div v-if="meetingMinute.deadline" class="col-6 col-md-4">
                                            <div class="row">
                                                <div class="col-5 pr-0">
                                                    <label class="p-2 d-block title-block">Deadline</label>
                                                </div>
                                                <div class="col-7 pl-0">
                                                    <label class="p-2 d-block header-details">{{ meetingMinute.deadline | formatDate }}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="meetingMinute.initiatedPerson" class="col-6 col-md-4">
                                            <div class="row">
                                                <div class="col-5 pr-0">
                                                    <label class="p-2 d-block title-block">Initiated By</label>
                                                </div>
                                                <div class="col-7 pl-0">
                                                    <label class="p-2 d-block header-details">{{ meetingMinute.initiatedPerson }}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="meetingMinute.responsiblePerson" class="col-6 col-md-4">
                                            <div class="row">
                                                <div class="col-5 pr-0">
                                                    <label class="p-1 pt-2 pb-2 d-block title-block">Person Responsible</label>
                                                </div>
                                                <div class="col-7 pl-0">
                                                    <label class="p-2 d-block header-details">{{ meetingMinute.responsiblePerson }}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div v-if="meetingMinute.description" class="col-12 col-md-12">
                                            <div class="card mb-2">
                                                <div class="card-body py-1 px-0">
                                                    <p class="contact-title mb-2 ml-2">
                                                        <span>Description</span>
                                                        <a href="javascript:" title="Assign as Task" class="float-right" @click="assignTask(meetingMinute, meetingMinute.description)" v-b-modal.ermAssignments><i class="fa fa-user-plus px-2"></i></a>
                                                    </p>
                                                    <div class="row">
                                                        <div class="col-12 col-md-12 m-0 px-3">
                                                            <label class="p-2 mb-0 d-block header-details" v-html="meetingMinute.description"></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="meetingMinute.discussion" class="col-12 col-md-12">
                                            <div class="card mb-2">
                                                <div class="card-body py-1 px-0">
                                                    <p class="contact-title mb-2 ml-2">
                                                        <span>Discussion</span>
                                                        <a href="javascript:" title="Assign as Task" class="float-right" @click="assignTask(meetingMinute, meetingMinute.discussion)" v-b-modal.ermAssignments><i class="fa fa-user-plus px-2"></i></a>
                                                    </p>
                                                    <div class="row">
                                                        <div class="col-12 col-md-12 m-0 px-3">
                                                            <label class="p-2 mb-0 d-block header-details" v-html="meetingMinute.discussion"></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="meetingMinute.decision" class="col-12 col-md-12">
                                            <div class="card mb-2">
                                                <div class="card-body py-1 px-0">
                                                    <p class="contact-title mb-2 ml-2">
                                                        <span>Decision / Action Items</span>
                                                        <a href="javascript:" title="Assign as Task" class="float-right" @click="assignTask(meetingMinute, meetingMinute.decision)" v-b-modal.ermAssignments><i class="fa fa-user-plus px-2"></i></a>
                                                    </p>
                                                    <div class="row">
                                                        <div class="col-12 col-md-12 m-0 px-3">
                                                            <label class="p-2 mb-0 d-block header-details" v-html="meetingMinute.decision"></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="meetingMinute.actionTaken" class="col-12 col-md-12">
                                            <div class="card mb-2">
                                                <div class="card-body py-1 px-0">
                                                    <p class="contact-title mb-2 ml-2">
                                                        <span>Action Taken</span>
                                                        <a href="javascript:" title="Assign as Task" class="float-right" @click="assignTask(meetingMinute, meetingMinute.actionTaken)" v-b-modal.ermAssignments><i class="fa fa-user-plus px-2"></i></a>
                                                    </p>
                                                    <div class="row">
                                                        <div class="col-12 col-md-12 m-0 px-3">
                                                            <label class="p-2 mb-0 d-block header-details" v-html="meetingMinute.actionTaken"></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </b-card-body>
                            </b-card>
                        </template>
                    </div>
                </div>
            </div>
            <div v-else class="text-center">
                <p>No Meeting Minutes Found</p>
            </div>
        </div>
        <QuickTaskPopup ref="ermAssignmentsRef" :masterData="masterData" />
    </div>
</template>
<script>
import dayList from '@/json/daysofweek.json';
import QuickTaskPopup from '@/components/popup/action/QuickTaskPopup.vue';
export default {
    components: {
        QuickTaskPopup
    },
    props: {
        momData: Object
    },
    data() {
        return {
            loading: false,
            showAllMOM: false,
            popupData: {},
            eventParticipants: [],
            meetingMinuteList: [],
            days: dayList,

            masterData: {
                refErmId: 0,
                refErdId: 0,
                refEntityId: 0,
                refErdTitle: '',
                refErdDescription: '',
                refEntity: ''
            },
        }
    },
    computed: {
        meetingMinutes() {
            // if (this.showAllMOM) {
                return this.meetingMinuteList;
            // }
            // else
            //     return this.meetingMinuteList.filter(data => data.statusId);
        }
    },
    mounted() {
        this.getMeetingMinute();
    },
    methods: {
        getMeetingMinute: function(showAllMOM) {
            this.showAllMOM = showAllMOM ? showAllMOM : false;
            if (this.momData.meetingDate && this.momData.calendarEventId) {
                let data = {
                    entityRecordMasterId: this.momData.entityRecordMasterId,
                    calendarEventId: this.momData.calendarEventId,
                    meetingDate: this.momData.meetingDate,
                }
                this.loading = true;
                axios.post(this.$store.getters.getAPIBasePath + '/communication/meetingminutebydate/list', data)
                    .then((response) => {
                        this.loading = false;
                        let result = response.data;
                        this.meetingMinuteList = result.meetingMinuteList;
                    })
                    .catch(error => {
                        this.loading = false;
                        console.log(error);
                    });
            }
        },
        assignTask: function(data, refErdDescription) {
            console.log(data);
            this.masterData.refErmId = data.entityRecordMasterId;
            this.masterData.refErdId = data.id;
            this.masterData.refEntityId = '';
            this.masterData.refEntity = 'meeting_minute';
            this.masterData.refErdTitle = data.title;
            this.masterData.refErdDescription = refErdDescription;
            this.masterData.statusId = '';
            this.$refs.ermAssignmentsRef.onRefDataChange();
            console.log(this.masterData.refErmId, this.masterData.refEntityId);
        },
        closeDialog() {
            this.$emit('closeDialog');
        }
    }
}
</script>