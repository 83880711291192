<template>
    <div class="resource">
        <b-modal ref="resourceModal" size="md" centered id="resourceModal" modal-class="resourceModal" @hide="closeDialog" :title="resourceLookupTitle" :no-close-on-backdrop="true" ok-only ok-title="Close" ok-variant="secondary">
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <div class="p-2">
                    <b-card no-body class="mb-3">
                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-add>
                                <span v-if="!elements.resourceEdit"><i class="fa fa-plus-circle pr-2 action-link"></i>New Resource Type</span>
                                <span v-if="elements.resourceEdit">Edit</span>
                            </label>
                        </b-card-header>
                        <b-collapse id="accordion-add" accordion="my-accordion2" v-model="elements.resourceVisible" role="tabpanel">
                            <b-card-body class="p-1">
                                <div class="form-row">
                                    <!-- <b-input-group class="form-group py-2"> -->
                                        <!-- <div cols="2" class="align-middle float-left my-auto ml-4 mr-0 p-1" >Resource</div> -->
                                        <div v-if="!resourceCategoryId" class="col-4">
                                            <div class="form-group">
                                                <div class="required-field-block" :class="{ 'is-invalid': $v.resource.resourceCategoryId.$error }">
                                                    <select v-model="resource.resourceCategoryId" class="form-control">
                                                        <option value="">Select Category</option>
                                                        <option v-for="(info, index) in resourceCategories" :value="info.categoryLookupId">{{info.value}}</option>
                                                    </select>
                                                    <div class="required-icon"></div>
                                                </div>
                                                <label v-if="$v.resource.resourceCategoryId.$error && !$v.resource.resourceCategoryId.required" class="text-danger">Select Resource Category</label>
                                                <!-- <label for="resourceCategoryId">Resource Type</label> -->
                                            </div>
                                        </div>
                                        <b-col>
                                            <div class="required-field-block" :class="{ 'is-invalid': $v.resource.title.$error }">
                                                <b-form-input id="resource" v-model="resource.title" placeholder="Enter Resource Type"></b-form-input>
                                                <div class="required-icon"></div>
                                            </div>
                                            <label v-if="$v.resource.title.$error && !$v.resource.title.required" class="text-danger">Enter Resource Type</label>
                                        </b-col>
                                        <b-col cols="2">
                                            <div class="input-group p-0">
                                                <b-form-checkbox class="custom-toggle" v-model="resource.active" name="check-button" switch>
                                                </b-form-checkbox>
                                            </div>
                                        </b-col>                                
                                        <div cols="2" class="float-right">
                                            <div class="more-btns">
                                                <button type="submit" title="Save" @click="saveResourceData" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                <button type="submit" title="Clear" @click="clearResourceData" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                            </div>
                                        </div>
                                    <!-- </b-input-group> -->
                                </div>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                    <div class="row view-filter">
                        <div class="col-sm-12">
                            <div class="float-right">
                                <div class="dataTables_length">
                                    <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                        <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="float-left">
                                <div class="dataTables_filter">
                                    <label>
                                        <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                    </label>
                                </div>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                    <b-table show-empty small stacked="md" :busy="isBusy" sort-icon-left :items="resources" :fields="resourceFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                        <template v-slot:cell(active)="data">
                            <b-form-checkbox class="ml-3 float-left pointer" v-model="data.item.active" @change="assignActivationState(data.item)">
                            </b-form-checkbox>
                        </template>
                        <template v-slot:cell(actions)="data">
                            <a href="javascript:" title="Edit Types" @click="showResourceEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                            <a href="javascript:" title="Delete" @click="removeResource(data.item.id)" v-b-modal.removeResource><i class="fa fa-trash pl-2"></i></a>
                        </template>
                        <template v-slot:empty="scope">
                            <div class="text-center">No Resource found</div>
                        </template>
                        <template v-slot:emptyfiltered="scope">
                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                        </template>
                    </b-table>
                    <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>                                                
                    <b-pagination v-if="resources.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                </div>
            </b-overlay>
        </b-modal>
        <DeleteComponent id="removeResource" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Resource ?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    props: {
        resourceLookupTitle: String,
        resourceCategoryId: [String, Number],
    },
    data() {
        return {
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            elements: {
                resourceVisible: false,
                resourceEdit: false
            },
            showOverlay: false,
            isBusy: false,
            notEnabled: true,
            resource: {
                id: '',
                resourceId: '',
                projectId: localStorage.getItem("projectId"),
                projectTypeId: localStorage.getItem("projectTypeId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                resource: '',
                resourceCategoryId: '',
                description: '',
                active: true,
                createdById: localStorage.getItem("userId"),
                createdDt: '',
                lastUpdatedDt: '',
            },
            resourceCategories: [],
            resources: [],
            resourceFields: [
                // { key: 'index',label: 'SN'},
                { key: 'resourceId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Resource Type', sortable: true, sortDirection: 'desc' },
                { key: 'resourceCategory', label: 'Category', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            sortDesc: false,
            pageOptions: [5, 10],
            sortBy: '',
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            deleteParam: {
                id: 0
            },
        }
    },
    validations: {
        resource: {
            title: {
                required
            },
            resourceCategoryId: {
                required,
            },
        },
    },
    mounted() {
        this.getResourceList();
        this.lookupData();
    },
    methods: {
        getResourceList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                projectTypeId: this.projectTypeId,
                resourceCategoryId: this.resourceCategoryId,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/resource/list', data)
                .then((response) => {
                    let result = response.data;
                    this.resources = result.resourceList;
                    this.totalRows = this.resources.length;
                })
        },
        lookupData: function() {
            let data = {
                entity: 'resource_category',
            }
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/category', data)
                .then((response) => {
                    let result = response.data;
                    this.resourceCategories = result.categoryLookup;
                })
        },
        saveResourceData: function() {
            this.resource.resourceCategoryId = this.resourceCategoryId ?? this.resource.resourceCategoryId;
            this.$v.resource.$touch();
            if (!this.$v.resource.$invalid) {
                if (!this.matchTextValExists(this.resource.title, this.resource.id)) {
                    if (this.elements.resourceEdit) {
                        this.updateResource();
                    } else {
                        this.addResource();
                    }
                }
                else {
                    iziToast.info({
                        message: 'Resource already exist.',
                        position: 'topCenter'
                    });
                }
            }
        },
        addResource: function() {
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/add', this.resource)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        // this.$bvModal.hide('resourceModal');
                        this.getResourceList();
                        this.clearResourceData();
                        
                    }
                })
                .catch((error)=> {
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        updateResource: function() {
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/update', this.resource)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    console.log(result);
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        // this.$bvModal.hide('resourceModal');
                        this.getResourceList();
                        this.clearResourceData();
                    }
                })
                .catch((error)=> {
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        removeResource: function(id) {
            console.log(id)
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/resource/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.getResourceList();
                    }
                    else if (result.status == "CONFLICT") {
                        iziToast.warning({
                            message: result.message,
                            position: 'topRight'
                        });
                    }
                })
                .catch(error => {
                    iziToast.error({
                        title: 'Error',
                        position: 'topRight'
                    });
                });
        },
        assignActivationState: function(item) {
            this.isBusy = true;
            if (item.active == true) {
                this.resource.id = item.id;
                this.resource.active = 0;
                this.resource.entity = 'resource';
                this.updateActivationState();
            } else {
                this.resource.id = item.id;
                this.resource.active = 1;
                this.resource.entity = 'resource';
                this.updateActivationState();
            }
        },
        updateActivationState: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/activationstate/update', this.resource)
                .then((response) => {
                    this.isBusy = false;
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.getResourceList();
                        this.clearResourceData();
                    }
                })
                .catch((error)=> {
                    this.isBusy = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        clearResourceData: function() {
            this.resource.id = '';
            this.resource.resourceId = '';
            this.resource.title = '';
            this.resource.resourceCategoryId = '';
            this.resource.description = '';
            this.resource.createdById = localStorage.getItem("userId");
            this.resource.active = true;
            this.$v.resource.$reset();
            this.elements.resourceEdit = false;
        },
        showResourceEdit: function(items) {
            let data = items;
            this.elements.resourceVisible = true;
            this.elements.resourceEdit = true;

            this.resource.id = data.id;
            this.resource.resourceId = data.resourceId;
            this.resource.vhextAccountId = data.vhextAccountId;
            this.resource.projectId = data.projectId;
            this.resource.projectTypeId = data.projectTypeId;
            this.resource.title = data.title;
            this.resource.resourceCategoryId = data.resourceCategoryId;
            this.resource.resourceType = data.resourceType;
            this.resource.description = data.description;
            this.resource.active = data.active;
            this.resource.createdById = data.createdById;
            this.resource.createdBy = data.createdBy;
            this.resource.createdDt = data.createdDt;
            this.resource.lastUpdatedDt = data.lastUpdatedDt;
        },
        matchTextValExists: function(textVal, id) {
            let result = false;
            this.resources.map(data => {
                if (textVal && data.title && data.id !== id) {
                    if (data.title.toLowerCase() == textVal.toLowerCase()) {
                        result = true;
                    }
                }
            });
            return result;
        },
        closeDialog() {
            this.$emit('closeDialog');
        }

    }
}
</script>