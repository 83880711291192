<template>
    <div class="assignmentPopup">
        <b-modal no-close-on-backdrop no-close-on-esc :title="issueData.editMode ? 'Edit Issue' : 'Quick Issue'" no-enforce-focus @hide="closeDialog" id="quickIssuePopup" ref="quickIssuePopupRef" size="md" modal-class="">
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <b-container fluid>
                    <b-row class="mb-1">
                        <b-col cols="3 pt-2">Title</b-col>
                        <b-col>
                            <div class="required-field-block" :class="{ 'is-invalid': $v.issueData.title.$error }">
                                <b-form-input id="title" v-model="issueData.title" placeholder="Enter Issue Title" required></b-form-input>
                                <div class="required-icon"></div>
                            </div>
                            <label v-if="$v.issueData.title.$error && !$v.issueData.title.minLength" class="text-danger">Title must have at least {{$v.issueData.title.$params.minLength.min}} letters.</label>
                            <label v-if="$v.issueData.title.$error && !$v.issueData.title.required" class="text-danger">Please enter Title</label>
                            <label v-if="$v.issueData.title.$error && !$v.issueData.title.maxLength" class="text-danger">Title must not exceed {{$v.issueData.title.$params.maxLength.max}} letters.</label>
                        </b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col cols="3 pt-2">Issue Group</b-col>
                        <b-col cols="8 pr-1">
                            <div class="required-field-block" :class="{ 'is-invalid': $v.issueData.entityRecordMasterId.$error }">
                                <select v-model="issueData.entityRecordMasterId" class="form-control" @change="onErmChange()" :class="{ 'is-invalid': $v.issueData.entityRecordMasterId.$error }" id="entityRecordMasterId">
                                    <option value="">Select Issue Group</option>
                                    <option v-for="(info, index) in entityERMLookup" :value="info.id">{{info.value}}</option>
                                </select>
                                <div class="required-icon"></div>
                            </div>
                            <label v-if="$v.issueData.entityRecordMasterId.$error && !$v.issueData.entityRecordMasterId.required" class="text-danger">Please Select a Issue Group</label>
                        </b-col>
                        <a title="Create new Issue master" class="action-link" href="javascript:" @click="openReqPop"><i class="fa fa-cog setting-icon-alt" aria-hidden="true"></i></a>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col cols="3 pt-2">Type</b-col>
                        <b-col cols="8" class="pr-1">
                            <select v-model="issueData.issueTypeId" class="form-control">
                                <option value="">Select</option>
                                <option v-for="(info, index) in filteredIssueTypes" :value="info.id">{{info.value}}</option>
                            </select>
                        </b-col>
                        <a :title="typeLookupTitle" class="action-link" @click="typePopupKey++" href="javascript:" v-b-modal.quickIssueTypeLookupPopup><i class="fa fa-cog setting-icon-alt" aria-hidden="true"></i></a>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col cols="3 pt-2">Severity</b-col>
                        <b-col>
                            <select v-model="issueData.severityId" class="form-control">
                                <option value="">Select</option>
                                <option v-for="(info, index) in severities" :value="info.id">{{info.value}}</option>
                            </select>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="3 pt-2">Status</b-col>
                        <b-col>
                            <div class="required-field-block" :class="{ 'is-invalid': $v.issueData.statusId.$error }">
                                <select v-model="issueData.statusId" id="statusId" class="form-control">
                                    <option value="">Select</option>
                                    <option v-for="(info, index) in issueStatus" :value="info.id">{{info.value}}</option>
                                </select>
                                <div class="required-icon"></div>
                            </div>
                            <label v-if="$v.issueData.statusId.$error && !$v.issueData.statusId.required" class="text-danger">Please Select a Status</label>
                        </b-col>
                    </b-row>
                    <div class="row">
                        <div class="col-12 col-sm-12">
                            <div class=" mt-2 mb-3">
                                <div class="card-body py-1 px-0">
                                    <p class="mb-2 mt-2 pl-1"><span>Description</span></p>
                                    <div class="row">
                                        <div class="col-12 col-md-12 m-0 px-3">
                                            <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="issueData.description"></editor>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-container>
            </b-overlay>
            <template #modal-footer>
                <div class="w-100">
                    <b-button class="float-right" variant="outline-primary" size="sm" @click="cancelEvent">
                        Cancel
                    </b-button>
                    <b-button variant="primary" size="sm" :disabled="showOverlay" @click="saveData" class="float-right mr-2">
                        <span v-if="showOverlay" >
                            <b-spinner small label="Small Spinner" class="mr-2"></b-spinner>{{ issueData.editMode ? 'Updating..' : 'Adding..' }}
                        </span>
                        <span v-else>{{ issueData.editMode ? 'Update Issue' : 'Add Issue' }}</span>
                    </b-button>
                </div>
            </template>
        </b-modal>
        <TypeLookup id="quickIssueTypeLookupPopup" :key="typePopupKey" ref="typeLookupModal" :entity="typeEntity" :typeLookupTitle="typeLookupTitle" :projectId="projectId" :projectTypeId="projectTypeId" @refresh-lookup="lookupData" @closeDialog="showDialog=false" />
        <ERMMasterPopup id="quickIssueErmPopup" v-if="showDialog" :ermMasterInfo="ermMasterInfo" @refresh-lookup="lookupData();masterLookupRefresh();" @closeDialog="showDialog=false" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import TypeLookup from '@/components/popup/lookup/TypeLookup.vue'
import ERMMasterPopup from '@/components/popup/lookup/ERMMasterPopup.vue'
import Editor from '@tinymce/tinymce-vue';
import redirectMixin from "@/mixins/redirect";

export default {
    mixins: [redirectMixin],
    props: {
        issueData: Object,
    },
    components: {
        Editor,
        TypeLookup,
        ERMMasterPopup
    },
    data() {
        return {
            showOverlay: false,
            entityERMLookup: [],
            issueStatus: [],
            issueTypes: [],
            severities: [],
            ermObject: {
                id:   '',
                value:   '',
                projectId: '',
            },
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: this.issueData.projectId,
            projectTypeId: this.issueData.projectTypeId,
            userId: localStorage.getItem("userId"),
            entity: 'issue',
            typeEntity: 'issue_type',

            ermMasterInfo: {
                title: "Issue Master",
                entity: 'issue_master',
                refEntity: 'issue',
            },

            showDialog: false,
            entity: 'issue',
            typeLookupTitle: 'Issue Type Settings',
            typePopupKey: 0,

            weblinks: 'google.com',
            toolbar: 'undo redo preview forecolor bullist numlist | formatselect | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | weblinks',
            initObj: {
                height: 200,
                branding: false,
                menubar: false,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools', 'lists'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            },
        }
    },
    computed: {
        filteredIssueTypes() {
            if(this.issueData.projectId > 0){
                return this.issueTypes.filter(issueType => issueType.projectId === this.issueData.projectId);
            }else{
                return this.issueTypes.filter(issueType => !issueType.projectId);
            }
            
        },
    },

    mounted() {
        this.lookupData();
        // console.log(this.issueData)
    },
    validations: {
        issueData: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
            statusId: {
                required
            },
            entityRecordMasterId: {
                required
            }
        },
        // ermObject: {
        //     id: {
        //         required,
        //     }
        // },

    },
    methods: {
        lookupData: function() {
            // alert(localStorage.getItem("projectTypeId"));
            let data = {};
            if(localStorage.getItem("projectId") > 0){
                data = {
                    vhextAccountId: this.vhextAccountId,
                    projectId: localStorage.getItem("projectId"),
                    projectTypeId: localStorage.getItem("projectTypeId"),
                    userId: this.userId,
                    entity: 'issue',
                }
            }else{
                data = {
                    vhextAccountId: this.vhextAccountId,
                    userId: this.userId,
                    entity: 'issue',
                }
            }
            // console.log(data);
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/issue/issueboard/lookup/all', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.issueTypes = result.customTypeLookup;
                    this.issueStatus = result.statusLookup;
                    this.severities = result.severityLookup;
                    this.entityERMLookup = result.entityERMLookup;
                    // if(this.entityERMLookup && this.entityERMLookup.length > 0){
                    //     this.ermObject.id = this.entityERMLookup[0].id; 
                    //     this.ermObject.value = this.entityERMLookup[0].value; 
                    //     this.ermObject.projectId = this.entityERMLookup[0].projectId; 
                    //     this.onErmChange(this.ermObject);
                    // }
                    // console.log(this.entityERMLookup);
                    console.log(this.issueTypes);
                })
                .catch((error)=> this.showOverlay = false);
        },
        saveData: function() {
            this.$v.issueData.$touch();
            if (!this.$v.issueData.$invalid) {
                this.showOverlay = true;
                if (this.issueData.editMode) {
                    this.updateIssue();
                } else {
                    this.saveIssue();
                }
            }
        },
        saveIssue: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/project/issue/add', this.issueData)
                .then((response) => {
                    let result = response.data;
                    // console.log(result);
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.showOverlay = false;
                        this.$emit('refreshData');
                        this.cancelEvent();
                    }
                })
                .catch((error)=> this.showOverlay = false);
        },
        updateIssue: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/project/issue/update', this.issueData)
                .then((response) => {
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.showOverlay = false;
                        this.$emit('refreshData');
                        this.cancelEvent();
                    }
                })
                .catch((error)=> this.showOverlay = false);
        },
        onErmChange() {
            // alert(this.issueData.entityRecordMasterId);
            let ermData = {};
            ermData = this.entityERMLookup.filter(item => 
                item.id === this.issueData.entityRecordMasterId);
            // console.log(this.entityERMLookup);
            // console.log(ermData);
            if(ermData){
                this.ermObject.id = ermData[0].id;
                this.ermObject.projectId = ermData[0].projectId;

                this.issueData.projectId = this.ermObject.projectId;
                
            }
            this.filteredIssueTypes;

            // this.ermId = this.ermData.id;
            // this.entityRecordMasterData.e = this.ermTaskPlanData.entityRecordMasterId;
            // this.viewEntityRecordMaster();
            // this.filteredIssueTypes();
            console.log("erm",this.ermObject);

            // this.elements.editMode = false;
            
            // this.$emit("onTaskPlanErmChange", this.ermObject.entityRecordMasterId);
        },

        masterLookupRefresh() {
            this.$emit('masterLookupRefresh');
        },
        cancelEvent: function() {
            this.$v.issueData.$reset();
            this.$bvModal.hide('quickIssuePopup');
        },
        closeDialog() {
            this.$emit('closeDialog');
        },
        openReqPop() {
            this.showDialog = true;
            this.$nextTick(()=> {
                this.$bvModal.show('quickIssueErmPopup');
            })
        }
    }
}
</script>