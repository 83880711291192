import ActionItem from '@/views/landingbasedpages/LanActionItem.vue'
import ActionItemList from '@/views/actionitem/ActionItemList.vue'
import BudgetPlan from '@/views/landingbasedpages/plan/LanBudgetPlan.vue'
import BudgetPlanMasterList from '@/views/landingbasedpages/plan/LanBudgetPlanMasterList.vue'
import CalendarEvent from '@/views/landingbasedpages/LanCalendarEvent.vue'
import Candidate from '@/views/resource/Candidate.vue'
import ChatBox from '@/views/landingbasedpages/LanChatBox.vue'
import ClientProfile from '@/views/user/ClientProfile.vue'
import LeaveTimeCalendar from '@/views/communication/LeaveTimeCalendar.vue'
import CompanyProfile from '@/views/company/CompanyProfile.vue';
import Client from '@/views/landingbasedpages/LanClient.vue'
import Contact from '@/views/landingbasedpages/LanContact.vue'
import ContractPayroll from '@/views/landingbasedpages/finance/LanContractPayroll.vue'
import Department from '@/views/company/Department.vue'
import DepartmentBoard from '@/views/resource/DepartmentBoard.vue'
import Designation from '@/views/resource/Designation.vue'
import DevPlanMasterList from '@/views/landingbasedpages/LanDevPlanMasterList.vue'
import DocsMasterList from '@/views/landingbasedpages/LanDocsMasterList.vue'
import Docs from '@/views/landingbasedpages/LanDocs.vue'
import DocShare from '@/views/landingbasedpages/LanDocShare.vue'
import Email from '@/views/tools/Email.vue'
import EmpAttendanceTimeline from '@/views/resource/EmpAttendanceTimeline.vue'
import Employee from '@/views/landingbasedpages/LanEmployee.vue'
import EmployeeAttendance from '@/views/landingbasedpages/LanEmployeeAttendance.vue'
import EmpPayrollSetting from '@/views/resource/EmpPayrollSetting.vue'
import EmpPerformanceEvaluation from '@/views/resource/EmpPerformanceEvaluation.vue'
import EmpPositionChange from '@/views/resource/EmpPositionChange.vue'
import FinancialTxn from '@/views/landingbasedpages/finance/LanFinancialTxn.vue'
import FinancialTxnMasterList from '@/views/landingbasedpages/finance/LanFinancialTxnMasterList.vue'
import FinDayEnd from '@/views/finance/FinDayEnd.vue'
import FinTxnBoard from '@/views/landingbasedpages/finance/LanFinTxnBoard.vue'
import GeneralDocumentMasterList from '@/views/document/GeneralDocumentMasterList.vue'
import IssueBoard from '@/views/landingbasedpages/LanIssueBoard.vue'
import Issue from '@/views/project/Issue.vue'
import IssueMasterList from '@/views/landingbasedpages/LanIssueMasterList.vue'
import Job from '@/views/resource/Job.vue'
import LeaveBoard from '@/views/landingbasedpages/LanLeaveTimeBoard.vue'
import Mailbox from '@/views/landingbasedpages/LanMailbox.vue'
import MeetingMinute from '@/views/landingbasedpages/LanMeetingMinute.vue'
import AddMeetingMinute from '@/views/communication/AddMeetingMinute.vue'
import MeetingMinuteMasterList from '@/views/landingbasedpages/LanMeetingMinuteMasterList.vue'
// import MonthlyBudgetPlan from '@/views/landingbasedpages/plan/LanMonthlyBudgetPlan.vue'
import MyProfile from '@/views/landingbasedpages/LanMyProfile.vue'
import NonEmployee from '@/views/landingbasedpages/LanNonEmployee.vue'
import PayableReceivableBoard from '@/views/finance/AccountPayableReceivableBoard.vue'
import Payroll from '@/views/landingbasedpages/finance/LanPayroll.vue'
import PayrollCalculation from '@/views/finance/PayrollCalculation.vue'
import PayrollMasterList from '@/views/finance/PayrollMasterList.vue'
import Plan from '@/views/landingbasedpages/plan/LanPlan.vue'
import PlanMasterList from '@/views/landingbasedpages/plan/LanPlanMasterList.vue'
import PolicyDocument from '@/views/document/PolicyDocument.vue'
import Process from '@/views/process/Process.vue'
import ProcessPlan from '@/views/landingbasedpages/LanProcessPlan.vue'
import ProcessPlanMasterList from '@/views/landingbasedpages/LanProcessPlanMasterList.vue'
import ProjectFunding from '@/views/landingbasedpages/finance/LanProjectFunding.vue'
import PublishedDocument from '@/views/actionitem/PublishedDocument.vue'
import Recruitment from '@/views/resource/Recruitment.vue'
import ResourcePlan from '@/views/landingbasedpages/plan/LanResourcePlan.vue'
import ResourcePlanMasterList from '@/views/landingbasedpages/plan/LanResourcePlanMasterList.vue'
import ReviewConfig from '@/views/company/ReviewConfig.vue'
import SubContract from '@/views/landingbasedpages/LanSubContract.vue'
import TaskBoard from '@/views/landingbasedpages/LanTaskBoard.vue'
import TaskGanttChart from '@/views/task/TaskGanttChart.vue'
import TaskList from '@/views/task/TaskList.vue'
import TaskFinancialTracking from '@/views/landingbasedpages/LanTaskFinancialTracking.vue'
import EmployeeTaskTimeBoard from '@/views/task/EmployeeTaskTimeBoard.vue'
import TaskTracking from '@/views/landingbasedpages/LanTaskTracking.vue'
import TimeSheet from '@/views/task/TimeSheet.vue'
import TimeSheetMasterList from '@/views/landingbasedpages/LanTimeSheetMasterList.vue'
import TimeSheetReport from '@/views/task/TimeSheetReport.vue'
import TimeSheetReportMasterList from '@/views/landingbasedpages/LanTimeSheetReportMasterList.vue'
import UserManagement from '@/views/user/User.vue';
import WorkScheduleSetting from '@/views/company/WorkShift.vue';
import WorkShiftManagement from '@/views/landingbasedpages/LanWorkShiftManagement.vue'
import WorkflowRequest from '@/views/actionitem/WorkflowRequest.vue'

export default [
    {
        path: '/landing/actionitem',
        name: 'lanactionitem',
        component: ActionItem,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/actionitem/list',
        name: 'lanactionitemlist',
        component: ActionItemList,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/bpl/:ermId',
        name: 'lanbpl',
        component: BudgetPlan,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/bplmas/list',
        name: 'lanbplmaslist',
        component: BudgetPlanMasterList,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/calevt',
        name: 'lancalevt',
        component: CalendarEvent,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/can',
        name: 'lancan',
        component: Candidate,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM'  }
    },
    {
        path: '/landing/chatbox',
        name: 'lanchatbox',
        component: ChatBox,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/clientprofile',
        name: 'lanclientprofile',
        component: ClientProfile,
        meta: { layout: 'landing', accessedby: 'CLI' }
    },
    {
        path: '/landing/cal',
        name: 'lancal',
        component: LeaveTimeCalendar,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/cmppro',
        name: 'lancmppro',
        component: CompanyProfile,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/client',
        name: 'lanclient',
        component: Client,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/contact',
        name: 'lancontact',
        component: Contact,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/contractprl',
        name: 'lancontractprl',
        component: ContractPayroll,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/dept',
        name: 'landept',
        component: Department,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/dptbrd',
        name: 'landptbrd',
        component: DepartmentBoard,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/desig',
        name: 'landesig',
        component: Designation,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/dvpmas/list',
        name: 'landvpmaslist',
        component: DevPlanMasterList,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/pdcmas/list',
        name: 'lanpdcmaslist',
        component: DocsMasterList,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/docs',
        name: 'landocs',
        component: Docs,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/docshr/list',
        name: 'landocshr',
        component: DocShare,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/email',
        name: 'lanemail',
        component: Email,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/empatl',
        name: 'lanempatl',
        component: EmpAttendanceTimeline,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/emp',
        name: 'lanemp',
        component: Employee,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/empatt',
        name: 'lanempatt',
        component: EmployeeAttendance,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/eprset',
        name: 'laneprset',
        component: EmpPayrollSetting,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/empprf',
        name: 'lanempprf',
        component: EmpPerformanceEvaluation,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/emppch',
        name: 'lanemppch',
        component: EmpPositionChange,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/ftx/:ermId',
        name: 'lanftx',
        component: FinancialTxn,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/ftxmas/list',
        name: 'lanftxmaslist',
        component: FinancialTxnMasterList,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/dayend',
        name: 'landayend',
        component: FinDayEnd,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM'  }
    },
    {
        path: '/landing/ftxbrd',
        name: 'lanftxbrd',
        component: FinTxnBoard,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM'  }
    },
    {
        path: '/landing/gndmas/list',
        name: 'langndmaslist',
        component: GeneralDocumentMasterList,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/issbrd',
        name: 'lanissbrd',
        component: IssueBoard,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM'  }
    },
    {
        path: '/landing/issmas/list',
        name: 'lanissmaslist',
        component: IssueMasterList,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM'  }
    },
    {
        path: '/landing/issue/:ermId',
        name: 'lanissue',
        component: Issue,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM'  }
    },
    {
        path: '/landing/job',
        name: 'lanjob',
        component: Job,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM'  }
    },
    {
        path: '/landing/lvebrd',
        name: 'lanlvebrd',
        component: LeaveBoard,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/mailbox',
        name: 'lanmailbox',
        component: Mailbox,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/meetingminute/:ermId',
        name: 'lanmeetingminute',
        component: MeetingMinute,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/momadd',
        name: 'lanmomadd',
        component: AddMeetingMinute,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/mmnmas/list',
        name: 'lanmmnmaslist',
        component: MeetingMinuteMasterList,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    // {
    //     path: '/landing/monbpl/:ermId',
    //     name: 'lanmonbpl',
    //     component: MonthlyBudgetPlan,
    //     meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    // },
    {
        path: '/landing/prlmas/list',
        name: 'lanprlmaslist',
        component: PayrollMasterList,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/payroll/:ermId',
        name: 'lanpayroll',
        component: Payroll,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/prcalc',
        name: 'lanprcalc',
        component: PayrollCalculation,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/pln/:ermId',
        name: 'lanpln',
        component: Plan,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/plnmas/list',
        name: 'lanplnmaslist',
        component: PlanMasterList,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/poldoc/:ermId',
        name: 'lanpoldoc',
        component: PolicyDocument,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/prs',
        name: 'lanprs',
        component: Process,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/pmd/:ermId',
        name: 'lanpmd',
        component: ProcessPlan,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/pmdmas/list',
        name: 'lanpmdmaslist',
        component: ProcessPlanMasterList,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/myprofile',
        name: 'lanmyprofile',
        component: MyProfile,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/nonemp',
        name: 'lannonemp',
        component: NonEmployee,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/aprbrd',
        name: 'lanaprbrd',
        component: PayableReceivableBoard,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/prjfnd/:ermId',
        name: 'lanprjfnd',
        component: ProjectFunding,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/pbldoc',
        name: 'lanpbldoc',
        component: PublishedDocument,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/rec',
        name: 'lanrec',
        component: Recruitment,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/respln/:ermId',
        name: 'lanrespln',
        component: ResourcePlan,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/rplmas/list',
        name: 'lanresplnlist',
        component: ResourcePlanMasterList,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/rwc',
        name: 'lanrwc',
        component: ReviewConfig,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/subctr',
        name: 'lansubctr',
        component: SubContract,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/tskbrd',
        name: 'lantskbrd',
        component: TaskBoard,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/taskganttchart',
        name: 'lantaskganttchart',
        component: TaskGanttChart,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },    
    {
        path: '/landing/task',
        name: 'lantask',
        component: TaskList,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },    
    // {
    //     path: '/landing/tskbrd/:ermId',
    //     name: 'lantskbrd',
    //     component: TaskBoard,
    //     meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    // },
    {
        path: '/landing/tft',
        name: 'lantft',
        component: TaskFinancialTracking,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/ttmbrd',
        name: 'lantft',
        component: EmployeeTaskTimeBoard,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/ett',
        name: 'lanett',
        component: TaskTracking,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    // {
    //     path: '/landing/tms/:ermId',
    //     name: 'lantms',
    //     component: TimeSheet,
    //     meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    // },
    {
        path: '/landing/tms/list',
        name: 'lantmslist',
        component: TimeSheet,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },

    {
        path: '/landing/tmsmas/list',
        name: 'lantmsmaslist',
        component: TimeSheetMasterList,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    // {
    //     path: '/landing/tmr/:ermId',
    //     name: 'lantmr',
    //     component: TimeSheetReport,
    //     meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    // },
    {
        path: '/landing/tmr/list',
        name: 'lantmrlist',
        component: TimeSheetReport,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },

    {
        path: '/landing/tmrmas/list',
        name: 'lantmrmaslist',
        component: TimeSheetReportMasterList,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/usrmgt',
        name: 'lanusrmgt',
        component: UserManagement,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|CRM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/wrkshd',
        name: 'lanwrkshd',
        component: WorkScheduleSetting,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/empwrs',
        name: 'lanempwrs',
        component: WorkShiftManagement,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/wflreq',
        name: 'lanwflreq',
        component: WorkflowRequest,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
];