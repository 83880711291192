<template>
    <div class="referenceDetailPopup">
        <b-modal id="taskSatatusUpdatePopup" ref="taskSatatusUpdatePopupRef" @hide="closeDialog" modal-class="" no-enforce-focus :title="popupTitle ? popupTitle : 'Task Status Update'">
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <div class="card-body p-3">
                    <div class="row input-group-label">
                        <div class="col-12">
                            <b-input-group :prepend="taskData.taskId ? taskData.taskId.toString() : ''" class="mb-3">
                                <b-form-input aria-label="First name" disabled v-model="taskData.title"></b-form-input>
                                <b-input-group-append class="custom-input-group-append">
                                    <span class="input-group-text append-icon">
                                        <a href="javascript:" title="View Task" class="action-link" @click="viewItemDetails"><i class="fa fa-eye" aria-hidden="true"></i></a>
                                    </span>
                                </b-input-group-append>
                            </b-input-group>
                        </div>
                        <div class="col-sm-6 col-lg-6">
                            <label>Progress (%)</label>
                            <span class="d-block"><input type="range" v-model="taskData.progressPercentage" class="form-range w-100" min="0" max="100" step="5">
                                <span class="d-block">{{ taskData.progressPercentage ? taskData.progressPercentage : 0 }}%</span>
                            </span>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label for="statusId">Status</label>
                                <div class="required-field-block" :class="{ 'is-invalid': $v.taskData.statusId.$error }">
                                    <select v-model="taskData.statusId" class="form-control" :class="{ 'is-invalid': $v.taskData.statusId.$error }">
                                        <option value="">Select</option>
                                        <option v-for="(info, index) in filteredStatus" :value="info.id">{{info.value}}</option>
                                    </select>
                                    <div class="required-icon"></div>                                
                                </div>
                                <label v-if="$v.taskData.statusId.$error && !$v.taskData.statusId.required" class="text-danger">Please Select Status</label>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="row input-group-label">
                        <div class="col-sm-6 col-lg-6">
                            <div class="form-group">
                                <label for="startDate">Start Time</label>
                                <div class="input-group">
                                    <b-col class="col-6 pl-0 pr-0">
                                        <b-form-input type="date" v-model="taskData.startDateValue" :value="taskData.startDateValue" id="estimatedStartDt1" required></b-form-input>
                                    </b-col>
                                    <b-col class="col-6 pl-1 pr-0">
                                        <b-form-input type="time" v-model="taskData.startTimeValue" :value="taskData.startTimeValue" @input="setEffortFromTimeDiff" required></b-form-input>
                                    </b-col>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-lg-6">
                            <div class="form-group">
                                <label for="endDate">End Time</label>
                                <div class="input-group">
                                    <b-col class="col-6 pl-0 pr-0">
                                        <b-form-input type="date" v-model="taskData.endDateValue" :value="taskData.endDateValue" id="estimatedStartDt1" required></b-form-input>
                                    </b-col>
                                    <b-col class="col-6 pl-1 pr-0">
                                        <b-form-input type="time" v-model="taskData.endTimeValue" :value="taskData.endTimeValue" @input="setEffortFromTimeDiff" required></b-form-input>
                                    </b-col>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-lg-6">
                            <div class="form-group">
                                <label for="endDate">Effort</label>
                                <b-input-group size="sm" class="mb-2">
                                    <EffortInput ref="effortControl"  />
                                </b-input-group>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-12">
                            <div class="form-group">
                                <label>Description</label>
                                <VmxEditor v-model="taskData.description"></VmxEditor>
                            </div>
                        </div>
                    </div> -->
                </div>
            </b-overlay>
            <template #modal-footer>
                <div class="w-100">
                    <button type="submit" @click="cancelEditMode;$bvModal.hide('taskSatatusUpdatePopup');" class="btn btn-secondary float-right mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                    <button type="submit" @click="saveStatusChange" :disabled="showOverlay" class="btn btn-primary float-right mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                </div>
            </template>
        </b-modal>
        <TaskDetailPopup v-if="showDialog" id="statusUpdateTaskDetailPopup" ref="taskDetailPopupRef" :attachmentData="attachmentData" :referenceDetailId="referencePopupId" @closeDialog="showDialog=false" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import TaskDetailPopup from '@/components/popup/itemdetail/TaskDetailPopup.vue';
import VmxEditor from '@/components/usercontrols/VmxEditor';
export default {
    props: {
        statusUpdate: Object
    },
    components: {
        VmxEditor,
        TaskDetailPopup,
    },
    data() {
        return {
            elements: {
                editMode: false,
                visible: false,
                viewMode: false
            },
            showDialog: false,
            showOverlay: false,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            userId: localStorage.getItem("userId"),
            entity: 'task',

            popupTitle: '',
            referencePopupId: 0,
            attachmentData: {},
            isEffortRefLoaded: false,

            task: {},

            taskData: {
                id: '',
                taskId: '',
                title: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: '',
                progressPercentage: 0,
                startDate: '',
                endDate: '',
                workDay: '',
                workHour: '',
                workMinute: '',
                startDateValue: this.getDateValue(new Date()),
                startTimeValue: '',
                endDateValue: this.getDateValue(new Date()),
                endTimeValue: '',
                refEntity: 'task',
                statusId: '',
                activityId: '',
                status: '',
                createdById: localStorage.getItem("userId"),
                description: '',
            },

            status: [],
            filteredStatus: [],

            weblinks: 'google.com',
            toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | weblinks',
            initObj: {
                height: 150,
                branding: false,
                menubar: false,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools', 'lists'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            },

        }
    },

    computed: {

    },
       
    mounted() {
        this.lookupData();
        this.isEffortRefLoaded = false;
        this.initialSettings();

        if(this.statusUpdate.id){
            this.getTaskDetails(this.statusUpdate.id);
        }
    },
    // beforeUpdate() {
    //     if (this.isEffortRefLoaded) {
    //         this.isEffortRefLoaded = true
    //     }
    // },
    validations: {
        taskData: {
            statusId: {
                required,
            }
        },
    },
    watch: {
        'taskData.title'() {
            this.elements.editMode = false;
            this.elements.visible = false;
        },
    },
    methods: {
        initialSettings: function() {
            this.taskData.id = this.statusUpdate.id;
            this.taskData.taskId = this.statusUpdate.taskId;
            this.taskData.title = this.statusUpdate.title;
            this.taskData.entityRecordMasterId = this.statusUpdate.entityRecordMasterId;
            this.taskData.statusId = this.statusUpdate.statusId;
            this.taskData.status = this.statusUpdate.status;
            this.taskData.activityId = this.statusUpdate.activityId;
            this.taskData.progressPercentage = this.statusUpdate.progressPercentage ? this.statusUpdate.progressPercentage : 0;
            this.taskData.startDateValue = this.statusUpdate.startDateValue;
            this.taskData.endDateValue = this.statusUpdate.endDateValue;
            this.taskData.startTimeValue = this.statusUpdate.startTimeValue;
            this.taskData.endTimeValue = this.statusUpdate.endTimeValue;
            this.popupTitle = this.statusUpdate.popupTitle;
            this.setEffortFromTimeDiff();
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entity: this.entity,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/common/statusupdate/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.status = result.statusLookup;
                    this.filteredStatus = this.status;
                    console.log(result);
                })
        },
        getTaskDetails: function (taskId) {
            axios.get(this.$store.getters.getAPIBasePath + '/task/view/' + taskId)
                .then((response) => {
                    let result = response.data;
                    this.task = result.task;
                    console.log(this.task.assignedToId,this.userId);
                    if(this.task.assignedById == this.userId) {
                        this.filteredStatus = this.status;
                    }else{
                        this.filteredStatus = this.status.filter(item => item.statusCode.toLowerCase() === 'at');
                    }
                })
        },
        saveStatusChange: function() {
            if (this.taskData.startDate == '' || this.taskData.startDate == null) { 
                this.taskData.startDate = this.combineDateAndTime(this.taskData.startDateValue, this.taskData.startTimeValue) 
            }
            if (this.taskData.endDate == '' || this.taskData.endDate == null) { 
                this.taskData.endDate = this.combineDateAndTime(this.taskData.endDateValue, this.taskData.endTimeValue) 
            }
            console.log(this.taskData);
            // this.setEffortData();
            this.$v.taskData.$touch();
            if (!this.$v.taskData.$invalid) {
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/task/status/update', this.taskData)
                    .then((response) => {
                    this.showOverlay = false;
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.$emit('updateParent');
                            this.$bvModal.hide('taskSatatusUpdatePopup');
                            // this.clearData();
                        }
                    }).catch((error) => {
                        this.showOverlay = false;
                    })
            }
        },
        cancelEditMode: function() {
            console.log(this.taskData);
            this.elements.editMode = false;
            this.elements.visible = false;
            this.elements.viewMode = false;

            this.taskData.statusId = this.statusUpdate.statusId;
            this.taskData.status = this.statusUpdate.status;
            this.taskData.progressPercentage = this.statusUpdate.progressPercentage ? this.statusUpdate.progressPercentage : 0;
            this.taskData.description = '';
            this.taskData.startDate = '';
            this.taskData.endDate = '';
            this.taskData.workHour = '';
            this.taskData.workMinute = '';
            this.taskData.workDay = '';
            // this.$refs.effortControl.setEffortInputData('', '', '');
            this.$v.taskData.$reset();
        },
        viewItemDetails: function() {
            // this.detailViewData = data;
            this.referencePopupId = this.taskData.id;
            this.attachmentData.refEntity = 'task';
            this.attachmentData.refRecordId = this.taskData.id;
            this.showDialog = true;
            this.$nextTick(()=> {
                this.$refs.taskDetailPopupRef.getDetails(this.referencePopupId, 'task');
            })
        },
        // setEffortData: function() {
        //     this.taskData.workHour = this.$refs.effortControl.getHrs();
        //     this.taskData.workMinute = this.$refs.effortControl.getMins();
        //     this.taskData.workDay = this.$refs.effortControl.getDays();
        // },
        progressBarVariant: function(percentage) {
            if (percentage != null) {
                if (percentage < 25) {
                    return 'danger';
                } else if (percentage < 50) {
                    return 'warning';
                } else if (percentage < 75) {
                    return 'info';
                } else if (percentage < 100) {
                    return 'success';
                } else if (percentage == 100) {
                    return 'primary';
                }
            }
        },
        setEffortFromTimeDiff() {
            if (this.taskData.startTimeValue && this.taskData.endTimeValue) {
                let startDate = this.taskData.startDateValue ? this.taskData.startDateValue : this.getDateValue(new Date());
                let endDate = this.taskData.endDateValue ? this.taskData.endDateValue : this.getDateValue(new Date());

                let combStartDT = this.combineDateAndTime(startDate, this.taskData.startTimeValue);
                let combEndDT = this.combineDateAndTime(endDate, this.taskData.endTimeValue);

                let difference = this.getDateDifference(combStartDT, combEndDT);
                // this.$refs.effortControl.setEffortInputData(difference.days, difference.hours, difference.minutes);
            }
        },
        closeDialog() {
            this.$emit('closeDialog');
        },
    }
}
</script>