<template>
    <div>
        <EffortEsimationMaster :ermMasterInfo="ermMasterInfo"></EffortEsimationMaster>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import EffortEsimationMaster from '../../components/blocks/erm/ERMMaster.vue'
export default {
    name: 'app',
    components: {
        EffortEsimationMaster,
    },
    data() {
        return {
            elements: {
                showDetail: false,
            },
            ermMasterInfo: {
                title: "Effort Estimation Sheet",
                entity: 'effort_estimation_master',
                refEntity: 'effort_estimation',
                ermListRedirectURL: '/esmaster/list',
                erdRedirectURL: '/effortestimation/'  
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>