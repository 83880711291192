<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="row">
                    <div class="col-lg-8 col-sm-6">
                        <h4 class="pl-2 mb-0 fs-16">Admin Settings <span>- {{ activeSubModuleName }}</span></h4>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                            <ol class="breadcrumb pt-0 mb-0">
                                <li class="breadcrumb-item">
                                    <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                </li>
                                <li v-if="projId" class="breadcrumb-item">
                                    <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div class="separator mb-2"></div>
            </div>
        </div>
        <div class="form-row">
            <div class="col-12">
                <b-overlay :show="showOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
                    <!-- <div v-if="mainMenuBySubModule.length>0" class="card p-3">
                        <b-tabs content-class="mt-3" lazy>
                            <b-tab v-for="mainMenu,index in mainMenuBySubModule" :title="mainMenu.customMainMenu" :key="index">
                                <div class="card bg-light-grey-shade px-3">
                                    <div class="row">
                                        <div v-for="setting in fileredSubMenuByMainMenu(mainMenu)" class="col-md-4 py-3">
                                            <a href="javascript:" :title="setting.name" class="settings-box make-it-slow bg-white" @click="$router.push(setting.webUrl)">
                                                <div class="row col-md-12 ml-0 left-bar-tab p-2">
                                                    <div class="col-md-3 p-3">
                                                        <i class="fa fa-3x icon-b" :class="setting.icon ? setting.icon : 'fa-tag'" aria-hidden="true"></i>
                                                    </div>
                                                    <div class="col-md-8 text-left align-self-center p-2">
                                                        <p><b>{{ setting.displayName }}</b></p>
                                                        <p>{{ (setting.customSubMenu).includes('Setting') ? setting.displayName : setting.customSubMenu + ' Settings' }}</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </b-tab>
                        </b-tabs>
                    </div> -->
                    <div v-if="mainMenuBySubModule.length>0" class="card p-3">
                        <div class="card bg-light-grey-shade px-3">
                            <div class="row">
                                <div v-for="setting in subMenuBySubModule" class="col-md-4 py-3">
                                    <a href="javascript:" :title="setting.name" class="settings-box make-it-slow bg-white" @click="$router.push(setting.webUrl)">
                                        <div class="row col-md-12 ml-0 left-bar-tab p-2">
                                            <div class="col-md-3 p-3">
                                                <i class="fa fa-3x icon-b" :class="setting.icon ? setting.icon : 'fa-tag'" aria-hidden="true"></i>
                                            </div>
                                            <div class="col-md-8 text-left align-self-center p-2">
                                                <p><b>{{ setting.customSubMenu }}</b></p>
                                                <p>{{ (setting.displayName).includes('Setting') ? setting.displayName : setting.displayName + ' Settings' }}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-overlay>
            </div>
        </div>
    </div>
</template>
<script>
import { EventBus } from '@/main';
export default {
    name: 'vsasettings',
    data() {
        return {
            projectId: localStorage.getItem("projectId"),
            userId: localStorage.getItem("userId"),
            vmxServiceCode: localStorage.getItem("vmxServiceCode"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),

            showOverlay: false,
            activeComponent: '',
            // showComponent: false,
            activeTab: 101,

            settingModules: [],
            settingMainMenu: [],
            adminSettingMenuList: [],
        }
    },
    computed: {
        activeSubModule: {
            get() {
                return this.$store.state.adminSetting.subModuleId;
            },
            set(value) {
                this.$store.state.adminSetting.subModuleId = value;
            }
        },
        activeSubModuleName: {
            get() {
                return this.$store.state.adminSetting.subModule;
            },
            set(value) {
                this.$store.state.adminSetting.subModule = value;
            }
        },
        // showComponent: {
        //     get() {
        //         return this.$store.state.adminSetting.showComponent;
        //     },
        //     set(value) {
        //         this.$store.state.adminSetting.showComponent = value;
        //     }
        // },
        mainMenuBySubModule() {
            return this.settingMainMenu.filter(menu => menu.subModuleId == this.activeSubModule);
        },
        subMenuBySubModule() {
            return this.adminSettingMenuList.filter(menu => menu.subModuleId == this.activeSubModule);
        },
    },
    created() {
        // EventBus.$on('onAdminSettingReload', () => {
        //     this.onAdminSettingReload()
        // });
    },
    mounted() {
        this.getAdminMainMenu();
        this.getAdminSubMenu();
    },
    beforeDestroy() {
        // EventBus.$off('onAdminSettingReload');
        // this.activeSubModule = null;
        // this.showComponent = false;
    },

    methods: {
        getAdminMainMenu: function() {
            let data = {
                userId: this.userId,
                projectId: this.projectId,
                vmxServiceCode: this.vmxServiceCode,
            }
            // this.showComponent = false;
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/menu/vmxservice/adminsettingmainmenu', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.settingMainMenu = result.customMainMenu;
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        getAdminSubMenu: function() {
            let data = {
                userId: this.userId,
                projectId: this.projectId,
                vmxServiceCode: this.vmxServiceCode,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/menu/vmxservice/adminsettingsubmenu', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.adminSettingMenuList = result.adminSettingSubMenus;
                })
                .catch((error) => {
                    this.showOverlay = false;
                })
        },
        fileredSubMenuByMainMenu: function (menuData) {
            return this.subMenuBySubModule.filter(data => data.mainMenuId == menuData.id);
        },
        onAdminSettingReload() {
            this.getAdminMainMenu();
            this.getAdminSubMenu();
        }

    }
}
</script>