<template>
    <div class="menu">
        <div class="sub-menu servicemenu">
            <div class="scroll">
                <ul class="list-unstyled" data-link="SubMenu" id="menuTypes">
                    <li v-for="(info, index) in subMenu">
                        <router-link v-if="info.entityCount == 1" :to="info.webUrl">
                            <i :class="info.icon"></i>
                            <span class="d-inline-block">{{info.customMainMenu}}</span>
                        </router-link>
                        <a v-if="info.entityCount >1" href="javascript:" data-toggle="collapse" aria-expanded="false" :data-target="targetId(info.id)" class="rotate-arrow-icon collapsed">
                            <span><i :class="info.icon" class="inner-sub-menu"></i></span>
                            <span class="d-inline-block">{{info.customMainMenu}}</span>
                            <i class="fa fa-angle-down float-right"></i>
                        </a>
                        <div v-if="info.entityCount >1" :id="randomId(info.id)" class="collapse" data-parent="#menuTypes">
                            <ul class="list-unstyled inner-level-menu">
                                <li v-for="(info, index) in loadEntity(info.id)">
                                    <router-link :to="info.webUrl">
                                        <i :class="info.icon"></i> <span class="d-inline-block">{{info.customSubMenu}}</span>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
export default {

    data() {
        return {
            elements: {
                showLogoBlock: false
            },
            mainMenu: [],
            subMenu: [],
            entity: [],
            entityMenu: []
        }
    },
    mounted() {
        this.loadMenu();
    },
    methods: {
        loadMenu() {
            let data = {
                userId: localStorage.getItem("userId"),
                projectId: localStorage.getItem("projectId"),
            }

            axios.post(this.$store.getters.getAPIBasePath + '/lookup/custommainmenu', data)
                .then((response) => {
                    let result = response.data;       
                    this.mainMenu = result.customMainMenuLookup;
                    this.loadSubMenu(localStorage.getItem("projectId"));
                })

            axios.post(this.$store.getters.getAPIBasePath + '/lookup/customsubmenu', data)
                .then((response) => {
                    let result = response.data;
                    this.entity = result.customSubMenuLookup;
                })
        },
        targetId: function(id) {
            return "#collapseAuthorization" + id;
        },
        randomId: function(id) {
            return "collapseAuthorization" + id;
        },
        loadSubMenu(id) {
            this.subMenu = this.mainMenu.filter(function(menu) {
                if (menu.projectId == id)
                    return menu;
            });
        },
        loadEntity(id) {
            return this.entity.filter(function(menu) {
                if (menu.mainMenuId == id)
                    return menu;
            });
        }
    }
}
</script>