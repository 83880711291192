<template>
    <div>
        <ErrorReportMasterList :ermMasterInfo="ermMasterInfo"></ErrorReportMasterList>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import ErrorReportMasterList from '../../components/blocks/erm/ERMMasterList.vue'
export default {
    name: 'app',
    components: {
        ErrorReportMasterList,
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                showUATMasterList: false
            },
            ermMasterInfo: {
                title: "Error Report List",
                entity: 'error_report_master',
                refEntity: 'error_report',
                tooltip: 'New Error Report',
                redirectURLName: 'errorreport',
                newEntityMasterURL: 'errmas',
                redirectURL: '/errmas',
                actionName: 'Errors',
                previewURLName: 'errrep'
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>