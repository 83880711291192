<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-3 col-md-3 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-16">Services</h4>
                        </div>
                        <div class="col-lg-5 col-md-3 col-sm-6">
                            <!-- <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-1">
                                    <li class="breadcrumb-item">
                                        <b-button class="btn btn-xs float-right px-2" variant="outline-primary" title="Existing ServiceUtil" @click="showDialog=true;" v-b-modal.existingActivityLookup><i class="fa fa-plus-circle pr-1"></i></i>Existing ServiceUtil</b-button>
                                    </li>
                                </ol>
                            </nav> -->
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-7 col-xl-7">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">
                                <div id="scope-app" class="mb-3">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                                                <span><i class="fa fa-plus-circle pr-2 action-link"></i>New Service</span>
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="">
                                                <div class="row mb-2">
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="serviceUtil">Service</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.serviceUtil.title.$error }">
                                                                <input type="text" v-model="serviceUtil.title" maxlength="100" class="form-control" id="serviceUtil" placeholder="Enter Service Name">
                                                                <div class="required-icon"></div>
                                                            </div>
                                                            <label v-if="$v.serviceUtil.title.$error && !$v.serviceUtil.title.required" class="text-danger">Please enter Service Name</label>
                                                            <label v-if="$v.serviceUtil.title.$error && !$v.serviceUtil.title.maxLength" class="text-danger">Service Name must not exceed {{$v.serviceUtil.title.$params.maxLength.max}} letters.</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div class="form-group">
                                                            <label for="serviceUtilTypeId">Service Type</label>
                                                            <select v-model="serviceUtil.serviceUtilTypeId" class="form-control" id="serviceUtilTypeId">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in serviceUtilTypes" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                            <a :title="typeLookupTitle" class="setting-icon" href="javascript:" v-b-modal.serviceUtilTypeModal><i class="fa fa-cog" aria-hidden="true"></i></a>
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div class="form-group">
                                                            <label for="serviceId">Service Account Id</label>
                                                            <input type="text" v-model="serviceUtil.serviceId" maxlength="100" class="form-control" id="serviceId" placeholder="Enter Service Id">
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div class="form-group">
                                                            <label for="serviceProvider">Service Provider</label>
                                                            <input type="text" v-model="serviceUtil.serviceProvider" maxlength="100" class="form-control" id="serviceProvider" placeholder="Enter Service Provider">
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div class="form-group">
                                                            <label for="servicePeriodCode">Service Period</label>
                                                            <select v-model="serviceUtil.servicePeriodCode" class="form-control" id="servicePeriodCode">
                                                                <option value="">Select</option>
                                                                <option value="0">Life Time</option>
                                                                <option value="1">Weekly</option>
                                                                <option value="2">Monthly</option>
                                                                <option value="3">Yearly</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div class="form-group">
                                                            <label for="startDate">Service Start Date</label>
                                                            <input v-model="serviceUtil.startDate" type="date" id="startDate" class="form-control" >
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div class="form-group">
                                                            <label for="endDate">Service End Date</label>
                                                            <input v-model="serviceUtil.endDate" type="date" id="endDate" class="form-control" >
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="responsiblePersonId">Responsible Person</label>
                                                            <select v-model="serviceUtil.responsiblePersonId" class="form-control" id="responsiblePersonId">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div class="form-group">
                                                            <label for="statusId">Status</label>
                                                            <select v-model="serviceUtil.statusId" class="form-control" id="statusId">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in serviceUtilStatus" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="col-12">
                                                        <div class="card-body p-0 my-2">
                                                            <b-card no-body>
                                                                <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                                    <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.purchaseDetail>
                                                                        <label class="my-1">Other Details</label>
                                                                    </label>
                                                                </b-card-header>
                                                                <b-collapse id="purchaseDetail" accordion="my-accordion2" role="tabpanel">
                                                                    <b-card-body class="p-2 pb-0">
                                                                        <div class="row">
                                                                            <div class="col-4">
                                                                                <div class="form-group">
                                                                                    <label for="transactionById">Purchased By</label>
                                                                                    <select v-model="serviceUtil.transactionById" class="form-control" id="transactionById">
                                                                                        <option value="">Select</option>
                                                                                        <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-6">
                                                                                <div class="form-group">
                                                                                    <label for="cost">Cost</label>
                                                                                    <VhextMoneyControl id="cost" :value="Math.abs(Number(serviceUtil.cost))" @money-input="moneyControlInput" />
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-6">
                                                                                <div class="form-group">
                                                                                    <label for="paymentDueDate">Payment Due Date</label>
                                                                                    <input v-model="serviceUtil.paymentDueDate" type="date" id="paymentDueDate" class="form-control" >
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-6">
                                                                                <div class="form-group">
                                                                                    <label for="serviceUrl">Service Url</label>
                                                                                    <input v-model="serviceUtil.serviceUrl" type="text" id="serviceUrl" class="form-control" placeholder="Service Url">
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-6">
                                                                                <div class="form-group">
                                                                                    <label for="responsiblePersonId">Responsible Person</label>
                                                                                    <select v-model="serviceUtil.responsiblePersonId" class="form-control" id="responsiblePersonId">
                                                                                        <option value="">Select</option>
                                                                                        <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-4">
                                                                                <div class="form-group">
                                                                                    <label>Warranty Period</label>
                                                                                    <b-input-group size="sm" class="mb-2">
                                                                                        <DayMonthYearControl 
                                                                                            :dayVisible="false"
                                                                                            @vmx-value-input="vmxValueInput" 
                                                                                            :monthValue="serviceUtil.warrantyMonth"
                                                                                            :yearValue="serviceUtil.warrantyYear"
                                                                                            ref="dayMonthYearControl" />
                                                                                    </b-input-group>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </b-card-body>
                                                                </b-collapse>
                                                            </b-card>
                                                        </div>
                                                    </div> -->
                                                    <!-- <div class="col-1">
                                                        <div class="form-group">
                                                            <label for="active">Active</label>
                                                            <div class="input-group ">
                                                                <b-form-checkbox class="custom-toggle" v-model="serviceUtil.active" name="check-button" switch>
                                                                </b-form-checkbox>
                                                            </div>
                                                        </div>
                                                    </div> -->
                                                    <div class="col-12">
                                                        <label>Description</label>
                                                        <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="serviceUtil.description"></editor>
                                                    </div>
                                                </div>
                                                <button type="submit" @click="saveData" :diabled="showOverlay" class="btn btn-primary mb-0 mr-2 py-1 px-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                <button type="submit" @click="clearData" class="btn btn-primary mb-0 py-1 px-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table ref="serviceUtilTable" v-model="mainTableArray" show-empty small stacked="md" selectable :select-mode="selectMode" :busy="isBusy" sort-icon-left :items="serviceUtilList"  @row-selected="onRowSelected" :fields="serviceUtilFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>    
                                <template v-slot:cell(title)="data">
                                    <a class="tooltiptext action-link" href="javascript:" @click="viewItemDetails(data.item)">{{data.value}}</a>
                                </template>
                                <template v-slot:cell(active)="data">
                                    <b-form-checkbox class="ml-3" v-model="data.value" @change="assignActivationState(data.item)">
                                    </b-form-checkbox>
                                </template>
                                <!--<template v-slot:cell(title)="data">
                                    <a href="javascript:" @click="bindDetail(data.item)">{{data.value}}</a>
                                </template>-->
                                <template v-slot:cell(description)="data">
                                    <span v-html="data.value"></span>
                                </template>
                                <template v-slot:cell(actions)="data">
                                    <button type="submit" title="Service Transactions" @click="openServiceHistoryPopup(data.item)" class="btn btn-primary sbtn btn-xs py-1 px-2"><i class="fa fa-plus-circle pr-1"></i>Serv. Txns</button>
                                    <a href="javascript:" title="Delete" @click="deleteServiceUtil(data.item.id)" v-b-modal.deleteServiceUtil><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Services found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            
                            <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>

                            <b-pagination v-if="serviceUtilList.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 col-xl-5 pl-0 mb-4">
                    <div class="card h-100">
                        <div class="card-body p-3">
                            <b-overlay :show="showDetOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
                                <b-tabs v-show="!elements.visible && elements.showDetail" content-class="mt-3">
                                    <b-tab title="Primary Info">
                                        <div class="row input-group-label">
                                            <div class="col-5">
                                                <b-input-group prepend="Id" class="text mb-3">
                                                    <b-input-group-prepend is-text><b>{{ serviceUtilDetail.serviceUtilId }}</b></b-input-group-prepend>
                                                </b-input-group>
                                            </div>
                                            <div class="col-md-2 col-sm-2 pl-0 mr-0 ml-auto">
                                                <div class="more-btns text-center">
                                                    <button v-if="!elements.editMode" href="javascript:" @click="elements.editMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                                    </button>
                                                    <div v-if="elements.editMode">
                                                        <button type="submit" @click="updateServiceUtil" :diabled="showDetOverlay" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                        <button type="submit" @click="cancelEditMode" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="serviceUtilDet">Service</label>
                                                    <div class="required-field-block" :class="{ 'is-invalid': $v.serviceUtilDetail.title.$error }">
                                                        <input type="text" v-model="serviceUtilDetail.title" maxlength="100" class="form-control" id="serviceUtilDet" :disabled="!elements.editMode">
                                                        <div class="required-icon"></div>
                                                    </div>
                                                    <label v-if="$v.serviceUtilDetail.title.$error && !$v.serviceUtilDetail.title.required" class="text-danger">Please enter Service Name</label>
                                                    <label v-if="$v.serviceUtilDetail.title.$error && !$v.serviceUtilDetail.title.maxLength" class="text-danger">Service Name must not exceed {{$v.serviceUtilDetail.title.$params.maxLength.max}} letters.</label>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="form-group">
                                                    <label for="statusId">Status</label>
                                                    <select v-if="elements.editMode" v-model="serviceUtilDetail.statusId" class="form-control" id="statusId">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in serviceUtilStatus" :value="info.id">{{info.value}}</option>
                                                    </select>
                                                    <input v-else type="text" class="form-control disabled" :value="serviceUtilDetail.status" disabled>
                                                </div>
                                            </div>
                                            <div class="col-2">
                                                <div class="form-group">
                                                    <label>Active</label>
                                                    <b-form-checkbox class="custom-toggle" v-model="serviceUtilDetail.active" :disabled="!elements.editMode" name="check-button" switch>
                                                    </b-form-checkbox>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="serviceUtilTypeIdDet">Service Type</label>
                                                    <select v-model="serviceUtilDetail.serviceUtilTypeId" class="form-control" id="serviceUtilTypeIdDet" :disabled="!elements.editMode">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in serviceUtilTypes" :value="info.id">{{info.value}}</option>
                                                    </select>
                                                    <a :title="typeLookupTitle" class="setting-icon" href="javascript:" v-b-modal.serviceUtilTypeModal><i class="fa fa-cog" aria-hidden="true"></i></a>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="serviceIdDet">Service Account Id</label>
                                                    <input type="text" v-model="serviceUtilDetail.serviceId" maxlength="100" class="form-control" id="serviceIdDet" :disabled="!elements.editMode">
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="serviceProviderDet">Service Provider</label>
                                                    <input type="text" v-model="serviceUtilDetail.serviceProvider" maxlength="100" class="form-control" id="serviceProviderDet" :disabled="!elements.editMode">
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="servicePeriodCodeDet">Service Period</label>
                                                    <select v-model="serviceUtilDetail.servicePeriodCode" class="form-control" id="servicePeriodCodeDet" :disabled="!elements.editMode">
                                                        <option value="">Select</option>
                                                        <option value="0">Life Time</option>
                                                        <option value="1">Weekly</option>
                                                        <option value="2">Monthly</option>
                                                        <option value="3">Yearly</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="startDateDet">Service Start Date</label>
                                                    <input v-if="elements.editMode" v-model="serviceUtilDetail.startDate" type="date" id="startDateDet" class="form-control" >
                                                    <input v-else type="text" class="form-control disabled" :value="serviceUtilDetail.startDate | formatDate" disabled>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="endDateDet">Service End Date</label>
                                                    <input v-if="elements.editMode" v-model="serviceUtilDetail.endDate" type="date" id="endDateDet" class="form-control" >
                                                    <input v-else type="text" class="form-control disabled" :value="serviceUtilDetail.endDate | formatDate" disabled>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="serviceUrlDet">Service Url</label>
                                                    <b-input-group>
                                                        <b-form-input v-model="serviceUtilDetail.serviceUrl" type="text" id="serviceUrlDet" class="form-control" :disabled="!elements.editMode" ></b-form-input>
                                                        <b-input-group-append class="append-0" v-if="serviceUtilDetail.serviceUrl && !elements.editMode">
                                                            <a :href="serviceUtilDetail.serviceUrl" target="_blank" class="btn sbtn btn-primary  py-1 px-2">Go</a>
                                                        </b-input-group-append>
                                                    </b-input-group>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="card-body p-0 mb-2">
                                                    <b-card no-body>
                                                        <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                            <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.descriptionAcc2>
                                                                <label>Description</label>
                                                            </label>
                                                        </b-card-header>
                                                        <b-collapse id="descriptionAcc2" accordion="my-descriptionAcc2" role="tabpanel">
                                                            <b-card-body class="p-1 pb-0">
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class="form-group">
                                                                            <label>Description</label>
                                                                            <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :disabled="!elements.editMode" :toolbar="toolbar" :init="initObj" v-model="serviceUtilDetail.description"></editor>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </b-card-body>
                                                        </b-collapse>
                                                    </b-card>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="card-body p-0 mb-2">
                                                    <b-card no-body>
                                                        <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                            <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.moreDetail>
                                                                <label>More Details</label>
                                                            </label>
                                                        </b-card-header>
                                                        <b-collapse id="moreDetail" accordion="my-accordion1" role="tabpanel">
                                                            <b-card-body class="p-1 pb-0">
                                                                <div class="row">
                                                                    <div class="col-12 col-sm-6">
                                                                        <div class="form-group">
                                                                            <label for="createdBy">Created By</label>
                                                                            <input type="text" class="form-control disabled" id="createdBy" v-model="serviceUtilDetail.createdBy" readonly>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-sm-6">
                                                                        <div class="form-group">
                                                                            <label for="responsiblePersonIdDet">Responsible Person</label>
                                                                            <select v-model="serviceUtilDetail.responsiblePersonId" class="form-control" id="responsiblePersonIdDet" :disabled="!elements.editMode">
                                                                                <option value="">Select</option>
                                                                                <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-sm-6">
                                                                        <div class="form-group">
                                                                            <label for="startDate">Created Date</label>
                                                                            <div class="input-group" >
                                                                                <input type="text" class="form-control disabled" :value="serviceUtilDetail.createdDt | formatDate" readonly>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-sm-6">
                                                                        <div class="form-group">
                                                                            <label for="endDate">Last Updated Date</label>
                                                                            <div class="input-group">
                                                                                <input type="text" class="form-control disabled" :value="serviceUtilDetail.lastUpdatedDt | formatDate" readonly>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </b-card-body>
                                                        </b-collapse>
                                                    </b-card>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                    <b-tab title="Vendor">
                                        <div class="row">
                                            <div class="col-10">
                                            </div>      
                                            <div class="col-md-2 col-sm-2 pl-3 float-right">
                                                <div class="more-btns text-center">
                                                    <button v-if="!elements.contactEditMode" href="javascript:" @click="elements.contactEditMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                                    </button>
                                                    <div v-if="elements.contactEditMode">
                                                        <button type="submit" @click="saveContact" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                        <button type="submit" @click="getContacts();elements.contactEditMode = false;" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="mobile">Name</label>
                                                    <input type="text" class="form-control" id="mobile" :disabled="!elements.contactEditMode"  v-model="contactDetail.contactName">
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="mobile">Company</label>
                                                    <input type="text" class="form-control" id="mobile" :disabled="!elements.contactEditMode"  v-model="contactDetail.company">
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="mobile">Mobile</label>
                                                    <input type="text" class="form-control" id="mobile" :disabled="!elements.contactEditMode"  v-model="contactDetail.mobile">
                                                </div>
                                            </div>
                                            <div class="col-12  col-sm-6">
                                                <div class="form-group">
                                                    <label for="emailId">Email Id</label>
                                                    <input type="text" class="form-control" id="emailId" :disabled="!elements.contactEditMode" v-model="contactDetail.emailId">
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="phone">Phone</label>
                                                    <input class="form-control" type="text" id="phone" :disabled="!elements.contactEditMode" v-model="contactDetail.phone">
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="phone">Office Phones</label>
                                                    <input class="form-control" type="text" id="officePhone" :disabled="!elements.contactEditMode" v-model="contactDetail.officePhone">
                                                </div>
                                            </div>


                                            <div class="divider"></div>
                                            <!-- <label class="px-3 mb-2" for="phone">Address Info</label> -->
                                            <h6 class="px-3 mb-2">Address Info</h6> 
                                            <div class="col-12 col-lg-12 col-xl-12 pl-3 text-left">
                                            <div class="card mb-3">
                                                <div class="card-body p-2">
                                                    <div class="row px-2" >
                                                        <div class="col-6">
                                                            <div class="form-group ">
                                                                <label for="title">Building Name</label>
                                                                <input type="text" class="form-control" id="Address1" :disabled="!elements.contactEditMode" v-model="contactDetail.address1" >
                                                            </div>
                                                        </div>
                                                        <div class="col-6 ">
                                                            <div class="form-group ">
                                                                <label for="title">Street Name</label>
                                                                <input type="text" class="form-control" id="streetName" :disabled="!elements.contactEditMode" v-model="contactDetail.streetName">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row px-2" >
                                                        <div class="col-6 col-sm-6">
                                                            <div class="form-group">
                                                                <label for="city">City</label>
                                                                <input type="text" class="form-control" id="city" :disabled="!elements.contactEditMode" v-model="contactDetail.city">
                                                            </div>
                                                        </div>
                                                        <div class="col-6 col-sm-6">
                                                            <div class="form-group">
                                                                <label for="State">State</label>
                                                                <input type="text" class="form-control" id="State" :disabled="!elements.contactEditMode" v-model="contactDetail.state">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row px-2" >
                                                        <div class="col-6 col-sm-6">
                                                            <div class="form-group">
                                                                <label for="projectManager">Country</label>
                                                                <select v-if="elements.contactEditMode" v-model="contactDetail.countryId" class="form-control">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in countries" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                                <input v-if="!elements.contactEditMode" disabled v-model="contactDetail.country" type="text" class="form-control">
                                                            </div>
                                                        </div>
                                                        <div class="col-6 col-sm-6">
                                                            <div class="form-group ">
                                                                <label for="title">Pin Code</label>
                                                                <input type="text" class="form-control" id="pincode" :disabled="!elements.contactEditMode" v-model="contactDetail.pincode">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                            <div class="divider"></div>
                                        </div>
                                    </b-tab>
                                    <b-tab title="Attachments">
                                        <b-card no-body class="mb-3">
                                            <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                                <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-Document>
                                                    <span>
                                                        <i class="fa fa-plus-circle action-link pr-2" aria-hidden="true"></i>Add Document
                                                    </span>
                                                </label>
                                            </b-card-header>
                                            <b-collapse id="accordion-Document" accordion="my-accordion" role="tabpanel">
                                                <b-card-body class="p-3">
                                                    <div class="row">
                                                        <div class="col-lg-12 col-md-12">
                                                            <div class="form-row">
                                                                <div class="col-11">
                                                                    <div class="form-group">
                                                                        <!-- <label class="float-left invisible">Document Type</label> -->
                                                                        <div class="attachments mb-2">
                                                                            <b-form-file ref="fileinput" class="attachments-list" size="sm" placeholder="Choose a file." v-model="attachmentData.attachments">
                                                                            </b-form-file>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-1">
                                                                    <a href="javascript:" title="Save attachment" @click="saveAttachment()" class="float-right setting-icon action-link">
                                                                        <i class="fa fa-check pr-2"></i>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>                                            
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12">
                                                <div v-show="attachments.length != 0" >
                                                    <div class="px-2">
                                                        <p class="my-1 border p-2" v-for="(attachment, index) in attachments">
                                                            <span class="pr-3">{{index+1}}</span>
                                                            <!-- <span class="pr-3">{{attachment.documentType}}</span> -->
                                                            <i class="fa fa-file-image-o pr-2 ml-3 icon-doc" aria-hidden="true"></i>
                                                            <a :href="attachment.attachmentPath" target="_blank">{{attachment.fileName}}</a>
                                                            
                                                            <a href="javascript:" class="float-right button-remove px-2 attachments-remove" title="Delete Attachment" @click="deleteFile(attachment.id)" data-toggle="modal" v-b-modal.deleteAttachment>
                                                                <i class="fa fa-trash pr-2 action-link"></i>
                                                            </a>

                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                    <b-tab title="Ref. Links">
                                        <b-card no-body class="mb-3">
                                            <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                                <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-Document>
                                                    <span>
                                                        <i class="fa fa-plus-circle action-link pr-2" aria-hidden="true"></i>Add Reference Link
                                                    </span>
                                                </label>
                                            </b-card-header>
                                            <b-collapse id="accordion-Document" accordion="my-accordion" role="tabpanel">
                                                <b-card-body class="p-3">
                                                    <div class="attachments mb-3">
                                                        <div class="attachments-list d-inline-block">
                                                            <div class="form-row">
                                                                <div class="col-4">
                                                                    <input type="text" maxlength="100" class="form-control" v-model="attachmentLinkData.fileName" id="title" placeholder="Title">
                                                                </div>
                                                                <div class="col-8">
                                                                    <input type="text" maxlength="100" class="form-control" v-model="attachmentLinkData.attachmentPath" id="title" placeholder="Enter a Document Link">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <a href="javascript:" title="Save Link" @click="addDocumentLink" class="float-right  p-2 attachments-remove action-link"><i class="fa fa-check pr-2"></i></a>
                                                    </div>                                            
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12">
                                                <div v-if="attachmentLinks.length>0" class="px-2">
                                                    <p class="my-1 border p-2" v-for="(attachment, index) in attachmentLinks">
                                                        <span class="pr-3">{{index+1}}</span>
                                                        <i class="fa fa-external-link pr-2 ml-2 icon-doc" aria-hidden="true"></i>
                                                        <a :href="attachment.attachmentPath" target="_blank">
                                                            {{ attachment.fileName ? attachment.fileName : attachment.attachmentPath }}
                                                        </a>
                                                        
                                                        <a href="javascript:" class="float-right button-remove px-2 attachments-remove" title="Delete Attachment" @click="deleteFile(attachment.id)" data-toggle="modal" v-b-modal.deleteAttachment>
                                                            <i class="fa fa-trash pr-2 action-link"></i>
                                                        </a>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                    <b-tab title="Service Txns">
                                        <!-- <div class="card mb-3"> -->
                                            <div class="row">
                                                <div class="col-12 mt-2">
                                                    <div class="row view-filter">
                                                        <div class="col-sm-12">
                                                            <div class="float-left">
                                                                <div class="dataTables_filter">
                                                                    <label>
                                                                        <input type="search" v-model="filterRen" class="form-control form-control-sm" placeholder="Search...">
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <button type="submit" title="Service Transactions" @click="openServiceHistoryPopup(serviceUtilDetail)" class="btn btn-outline-primary sbtn btn-xs py-1 px-2 float-right">
                                                                <!-- <i class="fa fa-plus-circle pr-1"></i> -->
                                                                Manage Service Txns
                                                            </button>
                                                            <div class="clearfix"></div>
                                                        </div>
                                                    </div>
                                                    <b-table show-empty small class="preview-table" stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="serviceHistoryList" :fields="serviceHistoryFields" responsive="sm" :filter="filterRen" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" >
                                                        <template #cell(index)="data">
                                                            {{ data.index + 1 }}
                                                        </template>
                                                        <template v-slot:cell(title)="data">
                                                            <a class="tooltiptext action-link" href="javascript:" @click="viewServiceHistoryDetails(data.item)">{{data.value}}</a>
                                                        </template>
                                                        <template v-slot:cell(cost)="data">
                                                            {{ currencySymbol }} {{data.value}}
                                                        </template>

                                                        <template v-slot:cell(actions)="data">
                                                            
                                                            <div v-if="data.item.attachmentCount > 0" class="dropdown dropright d-inline">
                                                                <a title="Attachment" class="dropdown-toggle pl-2 fa fa-paperclip" href="#" @click="getAttachmentsDd(data.item.id)" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                </a>
                                                                <div class="dropdown-menu">
                                                                    <b-dropdown-item v-if="attachmentDdList.length==0"></b-dropdown-item>
                                                                    <div v-if="attachmentDdList.length>0">
                                                                        <ul class="attachmentList-ul p-0 m-0">
                                                                            <li v-for="attachment,index in attachmentDdList" :key="attachment.id" v-if="attachment.fileName && attachment.fileName !== null">
                                                                                <a :href="attachment.attachmentPath" class="action-link" target="_blank">{{attachment.fileName}}</a>

                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- <a href="javascript:" title="Delete" @click="deleteStatusUpdate(data.item.id)"  v-b-modal.deleteStatusUpdate><i class="fa fa-trash pl-2"></i></a> -->
                                                        </template>
                                                        <template v-slot:empty="scope">
                                                            <div class="text-center">No Transactions found</div>
                                                        </template>
                                                        <template v-slot:emptyfiltered="scope">
                                                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                        </template>
                                                    </b-table>
                                                    <b-pagination v-if="serviceHistoryList.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                                                </div>
                                            </div>
                                        <!-- </div> -->
                                    </b-tab>
                                </b-tabs>
                            </b-overlay>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ServiceDetailPopup v-if="showDialog" id="serviceDetailPopup" ref="serviceUtilDetailPopupRef" :attachmentData="attachmentData" :detailViewData="detailViewData" :referenceDetailId="referencePopupId" @closeDialog="showDialog = false" />
        <ServiceHistoryDetailPopup v-if="showDialog" id="serviceHistoryDetailPopup" ref="serviceHistoryDetailPopupRef" :attachmentData="attachmentData" :detailViewData="detailViewData" :referenceDetailId="referencePopupId" @closeDialog="showDialog = false" />
        <DeleteComponent id="deleteServiceUtil" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Service ?" />
        <DeleteComponent id="deleteAttachment" :onYes="onYesAttachment" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Attachment?" />
        <TypeLookup id="serviceUtilTypeModal" ref="typeLookupModal" :entity="typeEntity" :typeLookupTitle="typeLookupTitle" @refresh-lookup="lookupData" />
        <ServiceHistoryPopup id="serviceHistoryPopup" ref="serviceHistoryPopupRef" :key="serviceHistoryPopupKey" :serviceUtilId="serviceUtilId" :serviceUtilTitle="serviceUtilTitle" @refreshData="getServiceUtils" />
        <!-- <TypeLookup id="documentTypeModal" ref="documentTypeModal"  entity="document_type" typeLookupTitle="Document Type Setting" @refresh-lookup="lookupData(),getAttachment()" /> -->
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import Editor from '@tinymce/tinymce-vue';
import ServiceDetailPopup from '@/components/popup/itemdetail/ServiceDetailPopup.vue';
import ServiceHistoryDetailPopup from '@/components/popup/itemdetail/ServiceHistoryDetailPopup.vue';
import ServiceHistoryPopup from '@/components/popup/action/ServiceHistoryPopup.vue';
import TypeLookup from '@/components/popup/lookup/TypeLookup.vue';
import VhextMoneyControl from '@/components/usercontrols/VhextMoneyControl.vue'
import { required, minLength, maxLength } from "vuelidate/lib/validators";
export default {
    name: 'app',
    components: {
        Editor,
        ServiceHistoryPopup,
        ServiceHistoryDetailPopup,
        ServiceDetailPopup,
        TypeLookup,
        VhextMoneyControl,
    },
    data() {
        return {
            showOverlay: false,
            showDetOverlay: false,
            showDialog: false,
            elements: {
                showDetail: false,
                editMode: false,
                contactEditMode: false,
                visible:false
            },
            isBusy: false,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            currencySymbol: localStorage.getItem("currency"),
            entity: 'service_util',
            userId: localStorage.getItem("userId"),

            typeEntity: 'service_util_type',
            typeLookupTitle: 'Service Util Type Settings',

            detailViewData: {},
            referencePopupId: 0,

            serviceUtilId: '',
            serviceUtilTitle: '',
            serviceHistoryPopupKey: 0,

            mainTableArray: [],
            
            serviceUtil: {
                id: '',
                serviceUtilId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                serviceId: '',
                title: '',
                serviceUtilTypeId: '',
                serviceProvider: '',
                description: '',
                cost: '',
                startDate: '',
                endDate: '',
                servicePeriodCode: '',
                paymentDueDate: '',
                responsiblePersonId: '',
                transactionById: '',
                serviceUrl: '',
                statusId: '',
                active: true,
                createdById: localStorage.getItem("userId"),
            },
            serviceUtilDetail: {
                id: '',
                serviceUtilId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                serviceId: '',
                title: '',
                serviceUtilTypeId: '',
                serviceUtilType: '',
                serviceProvider: '',
                description: '',
                cost: '',
                startDate: '',
                endDate: '',
                servicePeriodCode: '',
                paymentDueDate: '',
                responsiblePersonId: '',
                responsiblePerson: '',
                transactionById: '',
                purchasedBy: '',
                serviceUrl: '',
                statusId: '',
                status: '',
                active: true,
                createdById: localStorage.getItem("userId"),
                createdBy: '',
                createdDt: '',
                lastUpdatedDt: '',
            },

            contactDetail: {
                id: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                refRecordId: '',
                refEntity: '',
                contactId: '',
                userId: '',
                contactName: '',
                firstName: '',
                middleName: '',
                lastName: '',
                profession: '',
                company: '',
                position: '',
                phone: '',
                officePhone: '',
                mobile: '',
                alternateMobile: '',
                emailId: '',
                alternateEmailId: '',
                address1: '',
                address2: '',
                streetName: '',
                city: '',
                state: '',
                country: '',
                countryId: '',
                pincode: '',
                timeZone: '',
                website: '',
                skypeId: '',
                facebookId: '',
                linkedinId: '',
                twitterId: '',
                gender: null,
                dateOfBirth: '',
                lastUpdatedDt: '',
                active: '',
                photo:'',
            },

            attachmentLinkData: {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                refEntityId: '',
                refRecordId: '',
                fileName: '',
                attachmentPath: '',
                typeCode: 'DSL',
                attachedById: '',
            },

            attachmentData: {
                attachments: [],
            },
            notEnabled: true,
            serviceUtilStatus: [],
            serviceUtilTypes: [],
            users: [],
            countries: [],
            attachmentList: [],
            attachmentDdList: [],

            serviceUtilList: [],
            serviceUtilFields: [
                {key:'index',label: 'SN'},
                // { key: 'serviceUtilId', label: 'Service ID', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Service', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                // { key: 'serviceId', label: 'Ser. Account Id', sortable: true, sortDirection: 'desc' },
                { key: 'serviceUtilType', label: 'Service Type', sortable: true, sortDirection: 'desc' },
                { key: 'serviceProvider', label: 'Ser. Provider', sortable: true, sortDirection: 'desc' },
                { key: 'startDate', label: 'Ser. Start Date', sortable: true, formatter: "formatDate", sortDirection: 'desc' },
                { key: 'endDate', label: 'Ser. End Date', sortable: true, formatter: "formatDate", sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],

            serviceHistoryList: [],
            serviceHistoryFields: [
                { key:'index',label: 'SN' },
                { key: 'title', label: 'Transaction Title', sortable: true, sortDirection: 'desc' },
                { key: 'startDate', label: 'Start Date', sortable: true, formatter: "formatDate", sortDirection: 'desc' },
                { key: 'endDate', label: 'Expiry Date', sortable: true, formatter: "formatDate", sortDirection: 'desc' },
                { key: 'cost', label: 'Amount', sortable: true, sortDirection: 'desc' },
                { key: 'paymentDueDate', label: 'Paym. Due', sortable: true, formatter: "formatDate", sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Att.' }
            ],

            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15,20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterRen: null,
            filterOn: [],
            deleteParam: {
                id: 0
            },

            weblinks: 'google.com',
            toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | weblinks',
            initObj: {
                height: 200,
                branding: false,
                menubar: false,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools', 'lists'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            },
        }
    },
    computed: {
        attachments() {
            return this.attachmentList.filter((data) => data.typeCode !== 'DSL');
        },
        attachmentLinks() {
            return this.attachmentList.filter((data) => data.typeCode == 'DSL');
        }
    },
    mounted() {
        this.getServiceUtils();
        this.lookupData();
    },
    validations: {
        serviceUtil: {
            title: {
                required,
                maxLength: maxLength(100)
            },
        },
        serviceUtilDetail: {
            title: {
                required,
                maxLength: maxLength(200)
            },                 
        },
    },
    methods: {
        getServiceUtils: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/company/serviceutil/list',data)
                .then((response) => {
                    let result = response.data;
                    if (result.serviceUtilList) {
                        this.serviceUtilList = result.serviceUtilList;
                        this.$nextTick(() => {
                            this.selectBTableRowById(this.serviceUtilDetail.id, this.mainTableArray, 'serviceUtilTable');
                        })
                        this.totalRows = this.serviceUtilList.length;
                    }
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                entity: this.entity,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/company/serviceutil/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.serviceUtilTypes = result.customTypeLookup;
                    this.serviceUtilStatus = result.statusLookup;
                    this.users = result.userLookup;
                    this.countries = result.countryLookup;
                })

        },
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        dateFilter: function(value) {
            if (value) {
                return moment(String(value)).format('DD-MMM-YYYY');
            }
        },
        saveData: function() {
            this.$v.serviceUtil.$touch();
            if (!this.$v.serviceUtil.$invalid) {
                this.saveServiceUtil();
            }
        },
        saveServiceUtil: function() {
            // this.serviceUtil.warrantyYear = this.$refs.dayMonthYearControl.getYears();
            // this.serviceUtil.warrantyMonth = this.$refs.dayMonthYearControl.getMonths();
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/company/serviceutil/add', this.serviceUtil)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.clearData();
                        this.getServiceUtils();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        updateServiceUtil: function() {
            this.showDetOverlay = true;
            // this.serviceUtilDetail.warrantyYear = this.$refs.dayMonthYearControlDet.getYears();
            // this.serviceUtilDetail.warrantyMonth = this.$refs.dayMonthYearControlDet.getMonths();
            this.$v.serviceUtilDetail.$touch();
            if (!this.$v.serviceUtilDetail.$invalid) {
                axios.post(this.$store.getters.getAPIBasePath + '/company/serviceutil/update', this.serviceUtilDetail)
                    .then((response) => {
                        this.showDetOverlay = false;
                        let result = response.data;
                        this.elements.editMode = false;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getServiceUtils();
                        }
                    })
                    .catch(error => {
                        this.showDetOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },
        viewActivity: function(id) {
            this.showDetOverlay = true;
            axios.get(this.$store.getters.getAPIBasePath + '/company/serviceutil/view/' + id)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    let serviceUtilDetail = result.serviceUtilView;
                    if (serviceUtilDetail) {                        
                        this.elements.showDetail = true;
                        this.serviceUtilDetail.id = serviceUtilDetail.id;
                        this.serviceUtilDetail.serviceUtilId = serviceUtilDetail.serviceUtilId;
                        this.serviceUtilDetail.vhextAccountId = serviceUtilDetail.vhextAccountId;
                        this.serviceUtilDetail.serviceId = serviceUtilDetail.serviceId;
                        this.serviceUtilDetail.title = serviceUtilDetail.title;
                        this.serviceUtilDetail.serviceUtilTypeId = serviceUtilDetail.serviceUtilTypeId;
                        this.serviceUtilDetail.serviceUtilType = serviceUtilDetail.serviceUtilType;
                        this.serviceUtilDetail.serviceProvider = serviceUtilDetail.serviceProvider;
                        this.serviceUtilDetail.description = serviceUtilDetail.description;
                        this.serviceUtilDetail.cost = serviceUtilDetail.cost;
                        this.serviceUtilDetail.startDate = serviceUtilDetail.startDate;
                        this.serviceUtilDetail.endDate = serviceUtilDetail.endDate;
                        this.serviceUtilDetail.servicePeriodCode = serviceUtilDetail.servicePeriodCode;
                        this.serviceUtilDetail.paymentDueDate = serviceUtilDetail.paymentDueDate;
                        this.serviceUtilDetail.responsiblePersonId = serviceUtilDetail.responsiblePersonId;
                        this.serviceUtilDetail.responsiblePerson = serviceUtilDetail.responsiblePerson;
                        this.serviceUtilDetail.transactionById = serviceUtilDetail.transactionById;
                        this.serviceUtilDetail.purchasedBy = serviceUtilDetail.purchasedBy;
                        this.serviceUtilDetail.serviceUrl = serviceUtilDetail.serviceUrl;
                        this.serviceUtilDetail.statusId = serviceUtilDetail.statusId;
                        this.serviceUtilDetail.status = serviceUtilDetail.status;
                        this.serviceUtilDetail.active = serviceUtilDetail.active;
                        this.serviceUtilDetail.createdById = serviceUtilDetail.createdById;
                        this.serviceUtilDetail.createdBy = serviceUtilDetail.createdBy;
                        this.serviceUtilDetail.createdDt = serviceUtilDetail.createdDt;
                        this.serviceUtilDetail.lastUpdatedDt = serviceUtilDetail.lastUpdatedDt;
                        // this.$refs.dayMonthYearControlDet.setValueInputData(serviceUtilDetail.warrantyYear, serviceUtilDetail.warrantyMonth, '');
                    }
                })
                .catch(error => {
                    this.showDetOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
                this.elements.editMode = false;
                this.elements.showDetail = true;
        },
        cancelEditMode: function() {
            this.viewActivity(this.serviceUtilDetail.id);
        },
        deleteServiceUtil: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/company/serviceutil/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getServiceUtils();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getServiceHistory: function(serviceUtilId) {
            if (serviceUtilId) {
                let data = {
                    serviceUtilId: serviceUtilId,
                    vhextAccountId: this.vhextAccountId,
                }
                console.log(data);
                this.showDetOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/company/servicehistory/list', data)
                    .then((response) => {
                        this.showDetOverlay = false;
                        let result = response.data;
                        this.serviceHistoryList = result.serviceHistoryList;
                        console.log(result);
                    })
                    .catch(error => {
                        this.showDetOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },
        addDocumentLink: function() {
            this.attachmentLinkData.vhextAccountId = this.vhextAccountId;
            this.attachmentLinkData.attachedById = this.userId;
            this.attachmentLinkData.refEntity = this.entity;
            this.attachmentLinkData.refRecordId = this.serviceUtilDetail.id;
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/attachment/add', this.attachmentLinkData)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getAttachment();
                    }
                })
                .catch(error => {
                    this.showDetOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        saveAttachment: function() {
            if (this.attachmentData.attachments.length == 0) {
                iziToast.info({
                    message: 'Please choose file.',
                    position: 'topRight'
                });
                return;
            } else {
                let formData = new FormData();    
                formData.append('files', this.attachmentData.attachments);
                formData.append('vhextAccountId', this.vhextAccountId);
                formData.append('attachedById', this.userId);
                formData.append('refEntity', this.entity);
                formData.append('refRecordId', this.serviceUtilDetail.id);
                this.showDetOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/attachment/upload', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(response => {
                        this.showDetOverlay = false;
                        let result = response.data;
                        if (result.status == "OK") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.attachmentData.attachments = [];
                            this.getAttachment();
                        }
                        console.log('Attachment uploaded');
                    })
                    .catch(error => {
                        this.showDetOverlay = false;
                        console.log('Error Attachment uploaded');
                    });
            }
        },
        getAttachment: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                refEntity: this.entity,
                refRecordId: this.serviceUtilDetail.id,
            }
            console.log(data);
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/attachment/list', data)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    console.log(result);
                    if(result){
                        this.attachmentList = result.attachmentList;
                    }
                })
        },
        getAttachmentsDd: function(refRecordId) {
            let data = {
                refEntity: 'service_history',
                refRecordId: refRecordId,
                vhextAccountId: this.vhextAccountId,
            } 
            this.attachmentDdList = {};
            axios.post(this.$store.getters.getAPIBasePath + '/attachment/list', data)
                .then((response) => {
                    let result = response.data;
                    this.attachmentDdList = result.attachmentList;
                })
        },
        deleteFile: function(id) {
            this.deleteParam.id = id;
        },
        onYesAttachment: function(id) {
            this.showDetOverlay = true;
            axios.delete(this.$store.getters.getAPIBasePath + '/attachment/delete/' + this.deleteParam.id)
                .then(response => {
                    this.showDetOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getAttachment();
                    }
                })
                .catch(error => {
                    this.showDetOverlay = false;
                    console.log(error);
                });
        },
        clearData: function() {
            this.elements.editMode = false;
            this.elements.visible = false;
            this.serviceUtil.id = '';
            this.serviceUtil.serviceUtilId = '';
            this.serviceUtil.vhextAccountId = localStorage.getItem("vhextAccountId");
            this.serviceUtil.serviceId = '';
            this.serviceUtil.title = '';
            this.serviceUtil.serviceUtilTypeId = '';
            this.serviceUtil.serviceProvider = '';
            this.serviceUtil.description = '';
            this.serviceUtil.cost = '';
            this.serviceUtil.startDate = '';
            this.serviceUtil.endDate = '';
            this.serviceUtil.servicePeriodCode = '';
            this.serviceUtil.paymentDueDate = '';
            this.serviceUtil.responsiblePersonId = '';
            this.serviceUtil.transactionById = '';
            this.serviceUtil.serviceUrl = '';
            this.serviceUtil.statusId = '';
            this.serviceUtil.active = true;
            this.serviceUtil.createdById = '';
            this.serviceUtil.createdById = localStorage.getItem("userId");
            this.$v.serviceUtil.$reset();
            // this.$refs.dayMonthYearControlDet.clearValueInputData();
        },
        onRowSelected: function(items) {
            if (items.length > 0){
                let data = items[0];
                this.elements.showDetail = true;
                this.elements.visible = false;
                this.elements.editMode = false;
                this.serviceUtilDetail.id = data.id;
                this.serviceUtilDetail.serviceUtilId = data.serviceUtilId;
                this.serviceUtilDetail.vhextAccountId = data.vhextAccountId;
                this.serviceUtilDetail.serviceId = data.serviceId;
                this.serviceUtilDetail.title = data.title;
                this.serviceUtilDetail.serviceUtilTypeId = data.serviceUtilTypeId;
                this.serviceUtilDetail.serviceUtilType = data.serviceUtilType;
                this.serviceUtilDetail.serviceProvider = data.serviceProvider;
                this.serviceUtilDetail.description = data.description;
                this.serviceUtilDetail.cost = data.cost;
                this.serviceUtilDetail.startDate = data.startDate;
                this.serviceUtilDetail.endDate = data.endDate;
                this.serviceUtilDetail.servicePeriodCode = data.servicePeriodCode;
                this.serviceUtilDetail.paymentDueDate = data.paymentDueDate;
                this.serviceUtilDetail.responsiblePersonId = data.responsiblePersonId;
                this.serviceUtilDetail.responsiblePerson = data.responsiblePerson;
                this.serviceUtilDetail.transactionById = data.transactionById;
                this.serviceUtilDetail.purchasedBy = data.purchasedBy;
                this.serviceUtilDetail.serviceUrl = data.serviceUrl;
                this.serviceUtilDetail.statusId = data.statusId;
                this.serviceUtilDetail.status = data.status;
                this.serviceUtilDetail.active = data.active;
                this.serviceUtilDetail.createdById = data.createdById;
                this.serviceUtilDetail.createdBy = data.createdBy;
                this.serviceUtilDetail.createdDt = data.createdDt;
                this.serviceUtilDetail.lastUpdatedDt = data.lastUpdatedDt;
                // this.$refs.dayMonthYearControlDet.setValueInputData(data.warrantyYear, data.warrantyMonth, '');
                this.getAttachment();
                this.getServiceHistory(this.serviceUtilDetail.id);
                this.getContacts();
            }
        },
        assignActivationState: function(item) {
            this.isBusy = true;
            if (item.active == true) {
                this.serviceUtil.entity = this.entity;
                this.serviceUtil.id = item.id;
                this.serviceUtil.active = 0;

                this.updateActivationState();

            } else {
                this.serviceUtil.entity = this.entity;
                this.serviceUtil.id = item.id;
                this.serviceUtil.active = 1;

                this.updateActivationState();
            }
            console.log(this.serviceUtil.id, this.serviceUtil.active);
        },
        updateActivationState: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/activationstate/update', this.serviceUtil)
                .then((response) => {
                    console.log(this.serviceUtil.id);
                    this.isBusy = false;
                    let result = response;
                    if (result.status == 200) {
                        iziToast.success({
                            title: 'Updated',
                            position: 'topCenter'
                        });
                    }
                    this.elements.showDetail = false;
                    this.serviceUtil.active = true;
                    this.getServiceUtils();
                })
                .catch((error)=> {
                    this.isBusy = false;
                    console.log(error)
                })
        },
        getContacts: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                refEntity: this.entity,
                refRecordId: this.serviceUtilDetail.id,
            }
            console.log(data);
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/contact/view', data)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    if (result.contact) {
                        this.contactDetail = result.contact;
                        console.log(this.contactDetail);
                    }
                    else {
                        this.clearContact();
                    }
                })
        },
        saveContact: function() {
            if (this.contactDetail.refRecordId == this.serviceUtilDetail.id) {
                this.updateContact();
            } else {
                this.addContact();
            }
        },
        addContact: function() {
            this.contactDetail.refRecordId = this.serviceUtilDetail.id;
            this.contactDetail.refEntity = this.entity;
            console.log(this.contactDetail);
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/contact/add', this.contactDetail)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getContacts();
                        this.clearContact();
                    }
                })
        },
        updateContact: function() {
            console.log(this.contactDetail);
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/contact/update', this.contactDetail)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    this.elements.editMode = false;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getContacts();
                        this.clearContact();
                    }
                })
        },
        clearContact: function() {
            this.elements.contactEditMode = false;
            this.contactDetail.id = '';
            this.contactDetail.refRecordId = '';
            this.contactDetail.refEntity = '';
            this.contactDetail.contactId = '';
            this.contactDetail.userId = '';
            this.contactDetail.contactName = '';
            this.contactDetail.firstName = '';
            this.contactDetail.middleName = '';
            this.contactDetail.lastName = '';
            this.contactDetail.profession = '';
            this.contactDetail.company = '';
            this.contactDetail.position = '';
            this.contactDetail.phone = '';
            this.contactDetail.officePhone = '';
            this.contactDetail.mobile = '';
            this.contactDetail.alternateMobile = '';
            this.contactDetail.emailId = '';
            this.contactDetail.alternateEmailId = '';
            this.contactDetail.address1 = '';
            this.contactDetail.address2 = '';
            this.contactDetail.streetName = '';
            this.contactDetail.city = '';
            this.contactDetail.state = '';
            this.contactDetail.countryId = '';
            this.contactDetail.pincode = '';
            this.contactDetail.timeZone = '';
            this.contactDetail.website = '';
            this.contactDetail.skypeId = '';
            this.contactDetail.facebookId = '';
            this.contactDetail.linkedinId = '';
            this.contactDetail.twitterId = '';
            this.contactDetail.gender = null;
            this.contactDetail.dateOfBirth = '';
            this.contactDetail.lastUpdatedDt = '';
            this.contactDetail.active = '';
        },
        viewItemDetails: function(data) {
            this.detailViewData = data;
            this.referencePopupId = data.id;
            this.attachmentData.refEntity = this.entity;
            this.attachmentData.refRecordId = data.id;
            this.showDialog= true;
            this.$nextTick(()=> {
                this.$refs.serviceUtilDetailPopupRef.getDetails(this.referencePopupId, null);
            })
        },
        viewServiceHistoryDetails: function(data) {
            this.detailViewData = data;
            this.referencePopupId = data.id;
            this.attachmentData.refEntity = 'service_history';
            this.attachmentData.refRecordId = data.id;
            this.showDialog= true;
            this.$nextTick(()=> {
                this.$refs.serviceHistoryDetailPopupRef.getDetails(this.referencePopupId, null);
            })
        },
        openServiceHistoryPopup:function(data){
            this.serviceUtilId = data.id;
            this.serviceUtilTitle = data.title;
            this.serviceHistoryPopupKey++;
            this.$nextTick(() => {
                this.$bvModal.show('serviceHistoryPopup');
            });
        },
        moneyControlInput: function(value) {
            this.serviceUtil.cost = Math.abs(value);
        },

    }
}
</script>
