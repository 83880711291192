<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Meeting Minutes</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-xl-12">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                        <b-overlay :show="showOverlay" opacity="0.4">
                            <div id="scope-app" class="mb-3">
                                <b-card no-body class="">
                                    <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                        <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                                            <span v-if="!elements.editMode && !elements.viewMode">
                                                <i class="fa fa-plus-circle pr-2 action-link"></i>Add Meeting Minutes
                                                <span class="float-right mr-5">{{ meetingDate | formatDateAlt }}</span>
                                            </span>
                                            <span v-if="elements.editMode && !elements.viewMode">Edit MoM</span> 
                                            <span v-if="elements.viewMode">View MoM</span>

<!--                                             <span v-if="elements.newMode && !elements.editMode && !elements.viewMode">New</span>
                                            <span v-if="elements.editMode && !elements.newMode && !elements.viewMode">Edit</span>
                                            <span v-if="elements.viewMode">View</span> -->
                                        </label>
                                    </b-card-header>
                                    <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                        <b-card-body class="">
                                            <div v-show="!elements.viewMode" >
                                                <div class="row mb-2">
                                                    <div class="col-12 col-md-6 col-lg-3">
                                                        <div class="form-group">
                                                            <label for="title">Title</label>
                                                            <div v-if="!elements.viewMode">
                                                                <div class="required-field-block" :class="{ 'is-invalid': $v.meetingMinute.title.$error }">
                                                                    <input type="text" class="form-control" v-model="meetingMinute.title" id="title" :class="{ 'is-invalid': $v.meetingMinute.title.$error }" placeholder="Enter Title">
                                                                    <div class="required-icon">
                                                                    </div>
                                                                </div>
                                                                <label v-if="$v.meetingMinute.title.$error && !$v.meetingMinute.title.minLength" class="text-danger">Title must have at least {{$v.meetingMinute.title.$params.minLength.min}} letters.</label>
                                                                <label v-if="$v.meetingMinute.title.$error && !$v.meetingMinute.title.required" class="text-danger">Please enter Title</label>
                                                                <label v-if="$v.meetingMinute.title.$error && !$v.meetingMinute.title.maxLength" class="text-danger">Title must not exceed {{$v.meetingMinute.title.$params.maxLength.max}} letters.</label>
                                                            </div>
                                                            <b-input-group v-if="elements.viewMode" :prepend="meetingMinute.defectDbId" class="mb-2">
                                                                <b-form-input aria-label="First name" disabled v-model="meetingMinute.title"></b-form-input>
                                                            </b-input-group>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-3">
                                                        <div class="form-group">
                                                            <label for="initiatedPerson">Initiated By</label>
                                                            <select v-model="meetingMinute.initiatedPersonId" id="initiatedPersonId" class="form-control">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div v-show="isMOM" class="col-12 col-md-6 col-lg-3">
                                                        <div class="form-group">
                                                            <label for="mom-act-duration">Duration</label>
                                                            <b-input-group size="sm">
                                                                <EffortInput :daysVisible="false" 
                                                                hoursId="mom-act-duration" 
                                                                @vmx-effort-input="effortControlMomActInput" 
                                                                :hrsValue="meetingMinute.actualHour"
                                                                :minsValue="meetingMinute.actualMinute"
                                                                ref="effortControlMomAct" />
                                                            </b-input-group>
                                                        </div>
                                                    </div>
                                                    <div v-show="!isMOM" class="col-12 col-md-6 col-lg-3">
                                                        <div class="form-group">
                                                            <label for="mp-duration">Duration</label>
                                                            <b-input-group size="sm">
                                                                <EffortInput :daysVisible="false" 
                                                                hoursId="mp-duration" 
                                                                @vmx-effort-input="effortControlMpEstInput" 
                                                                :hrsValue="meetingMinute.estimatedHour"
                                                                :minsValue="meetingMinute.estimatedMinute"
                                                                ref="effortControlMpEst" />
                                                            </b-input-group>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-3">
                                                        <div class="form-group">
                                                            <label for="responsiblePerson">Person Responsible</label>
                                                            <select v-model="meetingMinute.responsiblePersonId" id="responsiblePersonId" class="form-control">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="col-12" :class="isMOM ? 'col-md-6 col-lg-6' : 'col-md-12 col-lg-12'">
                                                        <div class="form-group">
                                                            <label for="description">Agenda Item</label>
                                                            <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="meetingMinute.description" :disabled="elements.viewMode"></editor>
                                                        </div>
                                                    </div>
                                                    <div v-if="isMOM" class="col-6">
                                                        <div class="form-group">
                                                            <label for="discussion">Discussion</label>
                                                            <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="meetingMinute.discussion" :disabled="elements.viewMode"></editor>
                                                        </div>
                                                    </div>

                                                    <div v-if="isMOM" class="col-6">
                                                        <div class="form-group">
                                                            <label for="decision">Decision / Action Items</label>
                                                            <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="meetingMinute.decision" :disabled="elements.viewMode"></editor>
                                                        </div>
                                                    </div>

                                                    <div v-if="isMOM" class="col-6">
                                                        <div class="form-group">
                                                            <label for="actionTaken">Action Taken</label>
                                                            <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="meetingMinute.actionTaken" :disabled="elements.viewMode"></editor>
                                                        </div>
                                                    </div>
                                                    <div v-if="isMOM" class="col-12 col-md-6">
                                                        <div class="form-group">
                                                            <label for="deadline">Deadline</label>
                                                            <div>
                                                                <input v-model="meetingMinute.deadline" type="date" class="form-control" placeholder="Deadline">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-if="isMOM" class="col-12 col-md-6">
                                                        <div class="form-group">
                                                            <label for="statusId">Status</label>
                                                            <select v-if="!elements.viewMode" v-model="meetingMinute.statusId" class="form-control" id="statusId">
                                                                <option v-for="(info, index) in meetingMinuteStatus" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                            <input v-if="elements.viewMode" type="text" disabled class="form-control" v-model="meetingMinute.status">
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="submit" @click="saveData" :disabled="showOverlay" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                            </div>
                                            <div v-if="elements.viewMode">
                                                <div class="row">
                                                    <div class="col-12 col-sm-4">
                                                        <label for="title1">Title</label>
                                                        <b-input-group :prepend="meetingMinute.userRequirementId" class="mb-2">
                                                            <b-form-input aria-label="First name" id="title1" disabled v-model="meetingMinute.title"></b-form-input>
                                                        </b-input-group>
                                                    </div>
                                                    <div class="col-12 col-sm-2">
                                                        <div class="form-group">
                                                            <label>Initiatted By</label>
                                                            <input type="text" class="form-control" disabled v-model="meetingMinute.initiatedPerson">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-2">
                                                        <div class="form-group">
                                                            <label>Deadline</label>
                                                            <div class="input-group date disabled-date" disabled>
                                                                <input type="text" class="form-control" disabled :value="meetingMinute.deadline | formatDate" readonly>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-2">
                                                        <div class="form-group">
                                                            <label>Person Responsible</label>
                                                            <input type="text" class="form-control" disabled v-model="meetingMinute.responsiblePerson">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-2">
                                                        <div class="form-group">
                                                            <label>Status</label>
                                                            <input type="text" class="form-control" disabled v-model="meetingMinute.status">
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="row">
                                                    <div v-if="meetingMinute.description.length!=0" class="col-12 col-sm-12">
                                                        <div class="form-group">
                                                            <label>Action Item</label>
                                                        </div>
                                                        <p v-html="meetingMinute.description">{{meetingMinute.description}}</p>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12 col-sm-3">
                                                        <div class="form-group">
                                                            <label>Priority</label>
                                                            <input type="text" class="form-control" disabled v-model="meetingMinute.priority">
                                                        </div>
                                                    </div>

                                                    <div class="col-12 col-sm-2">
                                                        <div class="form-group">
                                                            <label for="startDate">Created Date</label>
                                                            <div class="input-group date disabled-date" disabled>
                                                                <input type="text" class="form-control disabled" :value="meetingMinute.createdDt | formatDate" readonly>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-2">
                                                        <div class="form-group">
                                                            <label>Updated Date</label>
                                                            <div class="input-group date disabled-date">
                                                                <input type="text" class="form-control disabled" :value="meetingMinute.lastUpdatedDt | formatDate" readonly>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="submit" @click="clearData" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                            </div>

                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                            </div>
                            <template #overlay>
                                <div class="text-center">
                                    <LoadingSpinner />
                                </div>
                            </template>
                            </b-overlay>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table show-empty small stacked="md" sort-icon-left :items="meetingMinutes" :fields="meetingMinuteFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template v-slot:cell(title)="data">
                                    <a class="tooltiptext action-link" href="javascript:" @click="viewItemDetails(data.item)">{{data.value}}</a>
                                </template>
                                <template v-slot:cell(description)="data">
                                    <a class="tooltiptext" href="javascript:" v-html="data.value" v-b-modal.htmlDetailView @click="htmlDetailView(data.item.description)"></a>
                                </template>
                                <template v-slot:cell(actualHour)="data">
                                    {{ formatDurationHM(data.item.actualHour, data.item.actualMinute) }}
                                </template>
                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="View" @click="showView(data.item)"><i class="fa fa-eye pl-2"></i></a>
                                    <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                    <!-- <a href="javascript:" title="Assign Task" @click="assignTask(data.item)" v-b-modal.ermAssignments><i class="fa fa-user-plus pl-2"></i></a> -->
                                    <a href="javascript:" title="Delete" @click="deleteMeetingMinute(data.item.id)" v-b-modal.deleteMeetingMinute><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Meeting Minute found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>

                            <b-pagination v-if="meetingMinute.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <TypeLookup ref="typeLookupModal" :entity="this.typeEntity" :typeLookupTitle="typeLookupTitle"  @refresh-lookup="lookupData"/>
        <HTMLDetailViewPopup ref="htmlDetailViewRef" :htmlData="htmlData" :htmlDetailViewTitle="htmlDetailViewTitle" />
        <!-- <QuickTaskPopup ref="ermAssignmentsRef" :masterData="masterData" /> -->
        <MeetingMinutesDetailPopup v-if="showDialog" ref="meetingMinutesDetailPopupRef" :detailViewData="detailViewData" :referenceDetailId="meetingMinutesPopupId" @closeDialog="closeDialog" />
        <DeleteComponent id="deleteMeetingMinute" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Meeting Minute?" />

    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import dayList from '@/json/daysofweek.json';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import TypeLookup from '@/components/popup/lookup/TypeLookup.vue';
import HTMLDetailViewPopup from '@/components/popup/itemdetail/HTMLDetailViewPopup.vue';
import MeetingMinutesDetailPopup from '@/components/popup/itemdetail/MeetingMinutesDetailPopup.vue';
import Editor from '@tinymce/tinymce-vue';
// import QuickTaskPopup from '@/components/popup/action/QuickTaskPopup.vue';
export default {
    name: 'app',
    components: {
                TypeLookup,
                HTMLDetailViewPopup,
                MeetingMinutesDetailPopup,
                Editor,
                // QuickTaskPopup
            },
    data() {
        return {
            showOverlay: false,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            
            entityRecordMasterId: this.$route.params.ermId,
            entity: 'meeting_minute',
            typeEntity: 'meeting_type',
            userId: localStorage.getItem("userId"),

            htmlData: '',
            typeLookupTitle:'Meeting Minute Type Settings',
            htmlDetailViewTitle: 'Action Item', 

            elements: {
                newMode: false,
                editMode: false,
                visible: false,
                viewMode: false,
            },

            showDialog: false,
            meetingMinuteStatus: [],
            users: [],
            meetingTypes: [],
            meetingMinute: {
                id: '',
                meetingMinuteId: '',
                calendarEventId: this.calendarEventId,
                eventId: this.eventId,
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: this.ermId,
                entity: 'meeting_minute',
                entityId: '',
                entityRefId: '',
                title: '',
                meetingtypeId: '',
                description: '',
                meetingPreparationId: '',
                discussion: '',
                decision: '',
                deadline: '',
                actionTaken: '',
                meetingDate: '',
                startDt: '',
                endDt: '',
                duration: '',
                estimatedHour: '',
                estimatedMinute: '',
                actualHour: '',
                actualMinute: '',
                initiatedPersonId: '',
                responsiblePersonId: '',
                statusId: '',

            },

            daysOfWeek: [],
            days: dayList,
            eventStartDate: null,
            eventEndDate: null,

            masterData: {
                refErmId: 0,
                refErdId: 0,
                refEntityId: 0,
                refErdTitle: '',
                refErdDescription: '',
                refEntity: ''
            },

            detailViewData: {},
            meetingMinutesPopupId: 0,

            meetingMinutes: [],
            meetingMinuteFields: [
                { key: 'meetingMinuteId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title',tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                { key: 'initiatedPerson', label: 'Initiated By',tdClass: 'table-name-col-size', sortable: true, sortDirection: 'desc' },
                { key: 'description', label: 'Agenda Item',tdClass: 'table-cw-300', sortable: true, sortDirection: 'desc' },
                { key: 'deadline',label: 'Deadline',formatter: "formatDate",sortable: true,sortDirection: 'desc' },
                { key: 'responsiblePerson', label: 'Person Responsible',tdClass: 'table-name-col-size', sortable: true, sortDirection: 'desc' },
                { key: 'actualHour', label: 'Duration' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            },
            toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | weblinks',
            initObj: {
                height: 300,
                branding: false,
                menubar: true,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools', 'lists'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            }
        }
    },
    computed: {
        ermId() {
            return localStorage.getItem("ermId");
        },
        eventId() {
            return this.$store.state.meetingMinute.eventId;
        },
        calendarEventId() {
            return this.$store.state.meetingMinute.calendarEventId;
        },
        meetingDate() {
            return this.$store.state.meetingMinute.meetingDate;
        },
        isMOM() {
            return this.$store.state.meetingMinute.isMOM;
        },
    },
    mounted() {
        if (!this.ermId || !this.eventId || !this.calendarEventId) {
            this.getPrevUrl();
        }
        this.lookupData();
        this.getMeetingMinute();
    },
    validations: {
        meetingMinute: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            }
        }
    },
    methods: {
        getMeetingMinute: function() {
            let data = {
                entityRecordMasterId: this.ermId,
                calendarEventId: this.calendarEventId,
                meetingDate: this.meetingDate,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/communication/meetingminutebydate/list', data)
            // axios.post(this.$store.getters.getAPIBasePath + '/communication/calendarevent/list', data)
                .then((response) => {
                    let result = response.data;
                    // this.meetingMinutes = result.calendarEventList;
                    this.meetingMinutes = result.meetingMinuteList;
                    this.meetingMinute.meetingPreparationId = this.meetingMinutes.length > 0 ? this.meetingMinutes[0].meetingPreparationId : null;
                    console.log(this.meetingMinutes);
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entity: this.entity,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/communication/meetingminute/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.meetingTypes = result.customTypeLookup;
                    this.meetingMinuteStatus = result.statusLookup;
                    this.users = result.userLookup;

                    this.meetingMinute.statusId = this.isMOM ? this.meetingMinuteStatus[0].id : '';
                })

        },
        saveData: function() {
            this.$v.meetingMinute.$touch();
            if (!this.$v.meetingMinute.$invalid) {
                if (this.isMOM) {
                    this.meetingMinute.actualHour = this.$refs.effortControlMomAct.getHrs();
                    this.meetingMinute.actualMinute = this.$refs.effortControlMomAct.getMins();
                }
                else {
                    this.meetingMinute.statusId = this.meetingMinute.statusId ? this.meetingMinute.statusId : '';
                    this.meetingMinute.estimatedHour = this.$refs.effortControlMpEst.getHrs();
                    this.meetingMinute.estimatedMinute = this.$refs.effortControlMpEst.getMins();
                }
                if (this.meetingMinutes.length == 0) {
                    this.meetingMinute.meetingPreparationId = null;
                }
                this.meetingMinute.meetingDate = this.getDateValue(this.meetingDate);

                if (this.elements.editMode) {
                    this.updateMeetingMinute();
                } else {
                    this.saveMeetingMinute();
                }
            }
        },
        saveMeetingMinute: function() {
            console.log(this.entityRecordMasterId,this.calendarEventId,this.meetingDate);
            this.meetingMinute.eventId = this.eventId;
            this.meetingMinute.calendarEventId = this.calendarEventId;
            this.meetingMinute.entityRecordMasterId = this.ermId;
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/communication/meetingminute/add', this.meetingMinute)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.meetingMinute.meetingPreparationId = result.id;
                        this.getMeetingMinute();
                        this.clearData();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        updateMeetingMinute: function() {
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/communication/meetingminute/update', this.meetingMinute)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getMeetingMinute();
                        this.clearData();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })

        },
        deleteMeetingMinute: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/communication/meetingminute/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getMeetingMinute();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        clearData: function() {
            this.elements.newMode = true;
            this.elements.editMode = false;
            this.elements.visible = false;
            this.elements.viewMode = false;
            this.meetingMinute.id = '';
            this.meetingMinute.meetingMinuteId = '';
            this.meetingMinute.title = '';
            this.meetingMinute.meetingtypeId = '';
            this.meetingMinute.entityId = '';
            this.meetingMinute.description = '';
            this.meetingMinute.meetingPreparationId = this.meetingMinutes.length > 0 ? this.meetingMinutes[0].meetingPreparationId : null;
            this.meetingMinute.discussion = '';
            this.meetingMinute.decision = '';
            this.meetingMinute.deadline = '';
            this.meetingMinute.actionTaken = '';
            this.meetingMinute.startDt = '';
            this.meetingMinute.endDt = '';
            this.meetingMinute.estimatedHour = '';
            this.meetingMinute.estimatedMinute = '';
            this.meetingMinute.actualHour = '';
            this.meetingMinute.actualMinute = '';
            this.meetingMinute.duration = '';
            this.meetingMinute.initiatedPersonId = '';
            this.meetingMinute.responsiblePersonId = '';
            this.meetingMinute.statusId = this.isMOM ? this.meetingMinuteStatus[0].id : '';
            this.$refs.effortControlMpEst.clearEffortInputData();
            this.$refs.effortControlMomAct.clearEffortInputData();
            this.$v.meetingMinute.$reset();
        },
        showEdit: function(data) {
            this.elements.newMode = false;
            this.elements.editMode = true;
            this.elements.visible = true;
            this.elements.viewMode = false;
            this.meetingMinute.id = data.id;
            this.meetingMinute.meetingMinuteId = data.meetingMinuteId;
            this.meetingMinute.vhextAccountId = data.vhextAccountId;
            this.meetingMinute.projectId = data.projectId;
            this.meetingMinute.entityRecordMasterId = data.entityRecordMasterId;
            this.meetingMinute.calendarEventId = data.calendarEventId;
            this.meetingMinute.eventId = data.eventId;
            this.meetingMinute.entityId = data.entityId;
            this.meetingMinute.title = data.title;
            this.meetingMinute.meetingtypeId = data.meetingtypeId;
            this.meetingMinute.description = data.description;
            this.meetingMinute.meetingPreparationId = data.meetingPreparationId;
            this.meetingMinute.discussion = data.discussion;
            this.meetingMinute.decision =data.decision;
            this.meetingMinute.deadline =data.deadline;
            this.meetingMinute.actionTaken = data.actionTaken;
            this.meetingMinute.meetingDate = data.meetingDate;
            this.meetingMinute.startDt = data.startDt;
            this.meetingMinute.endDt = data.endDt;
            this.meetingMinute.estimatedHour = data.estimatedHour;
            this.meetingMinute.estimatedMinute = data.estimatedMinute;
            if (this.isMOM) {
                this.meetingMinute.actualHour = data.estimatedHour;
                this.meetingMinute.actualMinute = data.estimatedMinute;
            } else {
                this.meetingMinute.actualHour = data.actualHour;
                this.meetingMinute.actualMinute = data.actualMinute;
            }
            this.meetingMinute.duration = data.duration;
            this.meetingMinute.initiatedPersonId = data.initiatedPersonId;
            this.meetingMinute.responsiblePersonId = data.responsiblePersonId;
            this.meetingMinute.statusId = data.statusId;
            this.$refs.effortControlMpEst.setEffortInputData('', data.estimatedHour, data.estimatedMinute);
            this.$refs.effortControlMomAct.setEffortInputData('', data.actualHour, data.actualMinute);
        },
        addMOM(data) {
            this.elements.editMode = false;
            this.meetingMinute.calendarEventId = data.id;
            this.meetingMinute.vhextAccountId = data.vhextAccountId;
            this.meetingMinute.projectId = data.projectId;
            this.meetingMinute.entityRecordMasterId = data.entityRecordMasterId;
            this.eventStartDate = data.startDate;
            this.eventEndDate = data.endDate;

            if (data.daysOfWeek && data.daysOfWeek !== null) {
                this.daysOfWeek = [];
                console.log(data.daysOfWeek)
                var b = data.daysOfWeek.split(',').map(Number);
                b.forEach((day) => {
                    this.daysOfWeek.push(day);
                })
            }
        },
        showView: function(data) {
            this.elements.visible = true;
            this.elements.viewMode = true;
            this.elements.editMode = false;
            this.elements.newMode = false;
            this.meetingMinute.id = data.id;
            this.meetingMinute.meetingMinuteId = data.meetingMinuteId;
            this.meetingMinute.vhextAccountId = data.vhextAccountId;
            this.meetingMinute.projectId = data.projectId;
            this.meetingMinute.entityRecordMasterId = data.entityRecordMasterId;
            this.meetingMinute.entityId = data.entityId;
            this.meetingMinute.title = data.title;
            this.meetingMinute.meetingtypeId = data.meetingtypeId;
            this.meetingMinute.description = data.description;
            this.meetingMinute.meetingPreparationId = data.meetingPreparationId;
            this.meetingMinute.discussion = data.discussion;
            this.meetingMinute.decision =data.decision;
            this.meetingMinute.deadline =data.deadline;
            this.meetingMinute.actionTaken = data.actionTaken;
            this.meetingMinute.startDt = data.startDt;
            this.meetingMinute.endDt = data.endDt;
            this.meetingMinute.duration = data.duration;
            this.meetingMinute.initiatedPerson = data.initiatedPerson;
            this.meetingMinute.responsiblePerson = data.responsiblePerson;
            this.meetingMinute.status = data.status;
            this.meetingMinute.createdDt = data.createdDt;
            this.meetingMinute.lastUpdatedDt = data.lastUpdatedDt;
        },
        redirectToAddPage: function (selectedDate) {
            if (selectedDate) {
                let data = {
                    eventId: moment(String(selectedDate)).format('YYYYMMDD'),
                    meetingDate: selectedDate,
                }
                this.$store.dispatch('setMeetingData', data);
                this.$nextTick(() => {
                    this.$router.push('/momadd');
                })
                console.log(this.$store.getters)
            }
        },
        dateDisabled(ymd, date) {
            let arr1 = this.daysOfWeek;
            let arr2 = [0,1,2,3,4,5,6];

            // To Find Array Differences
            let array = arr1.concat(arr2).filter(item => !arr1.includes(item) || !arr2.includes(item));

            let value = false;
            const weekday = date.getDay()
            if (array.length>0) {
                array.forEach((arr) => {
                    if (arr == weekday) {
                        value = true;
                    }
                })
            }
            return value;
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        htmlDetailView: function(data) {
            this.htmlData = data;
            console.log(data);
        },
        viewItemDetails: function(data) {
            this.detailViewData = data;
            this.meetingMinutesPopupId = data.id;
            this.showDialog= true;
            this.$nextTick(()=> {
                this.$refs.meetingMinutesDetailPopupRef.getDetails(this.meetingMinutesPopupId, null);
            })
        },
        effortControlMpEstInput:function(data){
            this.$refs.effortControlMpEst.setEffortInputData(data.day, data.hrs, data.mins);
        },
        effortControlMomActInput:function(data){
            this.$refs.effortControlMomAct.setEffortInputData(data.day, data.hrs, data.mins);
        },
        closeDialog: function() {
            console.log('closed');
            this.showDialog = false;
        },
        assignTask: function(data) {
            console.log(data);
            this.masterData.refErmId = data.entityRecordMasterId;
            this.masterData.refErdId = data.id;
            this.masterData.refEntityId = '';
            this.masterData.refEntity = this.entity;
            this.masterData.refErdTitle = data.title;
            this.masterData.refErdDescription = data.description;
            this.masterData.statusId = '';
            this.$refs.ermAssignmentsRef.onRefDataChange();
            console.log(this.masterData.refErmId, this.masterData.refEntityId);
        }   


    }
}
</script>