<template>
  <div v-if="editor">
    <div v-if="toolbar" class="toolbar">
      <!-- Undo & Redo -->
      <div class="toolbar-group">
        <button class="btn btn-light sbtn" @click="editor.chain().focus().undo().run()"
          :disabled="!editor.can().chain().focus().undo().run()" title="Undo">
          <i class="fa fa-undo"></i>
        </button>
        <button class="btn btn-light sbtn" @click="editor.chain().focus().redo().run()"
          :disabled="!editor.can().chain().focus().redo().run()" title="Redo">
          <i class="fa fa-repeat"></i>
        </button>
      </div>
      <!-- Text Formatting -->
      <div class="toolbar-group">
        <button class="btn btn-light sbtn" @click="editor.chain().focus().toggleBold().run()"
          :disabled="!editor.can().chain().focus().toggleBold().run()" :class="{ 'is-active': isActive('bold') }"
          title="Bold">
          <i class="fa fa-bold"></i>
        </button>
        <button class="btn btn-light sbtn" @click="editor.chain().focus().toggleItalic().run()"
          :disabled="!editor.can().chain().focus().toggleItalic().run()" :class="{ 'is-active': isActive('italic') }"
          title="Italic">
          <i class="fa fa-italic"></i>
        </button>
        <button class="btn btn-light sbtn" @click="editor.chain().focus().toggleUnderline().run()"
          :disabled="!editor.can().chain().focus().toggleUnderline().run()"
          :class="{ 'is-active': isActive('underline') }" title="Underline">
          <i class="fa fa-underline"></i>
        </button>
        <button class="btn btn-light sbtn" @click="editor.chain().focus().toggleStrike().run()"
          :disabled="!editor.can().chain().focus().toggleStrike().run()" :class="{ 'is-active': isActive('strike') }"
          title="Strike">
          <i class="fa fa-strikethrough"></i>
        </button>
        <!-- <button class="btn btn-light sbtn" @click="editor.chain().focus().unsetAllMarks().run();editor.chain().focus().clearNodes().run();" title="Clear Marks">
          <i class="fa fa-eraser"></i>
        </button> -->
      </div>

      <!-- Lists -->
      <div class="toolbar-group">
        <button class="btn btn-light sbtn" @click="editor.chain().focus().toggleBulletList().run()"
          :class="{ 'is-active': isActive('bulletList') }" title="Bullet List">
          <i class="fa fa-list-ul"></i>
        </button>
        <button class="btn btn-light sbtn" @click="editor.chain().focus().toggleOrderedList().run()"
          :class="{ 'is-active': isActive('orderedList') }" title="Ordered List">
          <i class="fa fa-list-ol"></i>
        </button>
      </div>

      <!-- Text Align -->
      <!-- <div class="toolbar-group">
        <button class="btn btn-light sbtn" @click="editor.chain().focus().setTextAlign('left').run()" :class="{ 'is-active': isActive({textAlign: 'left'}) }" title="Align Left">
          <i class="fa fa-align-left"></i>
        </button>
        <button class="btn btn-light sbtn" @click="editor.chain().focus().setTextAlign('center').run()" :class="{ 'is-active': isActive({textAlign: 'center'}) }" title="Align Center">
          <i class="fa fa-align-center"></i>
        </button>
        <button class="btn btn-light sbtn" @click="editor.chain().focus().setTextAlign('right').run()" :class="{ 'is-active': isActive({textAlign: 'right'}) }" title="Align Right">
          <i class="fa fa-align-right"></i>
        </button>
        <button class="btn btn-light sbtn" @click="editor.chain().focus().setTextAlign('justify').run()" :class="{ 'is-active': isActive({textAlign: 'justify'}) }" title="Justify">
          <i class="fa fa-align-justify"></i>
        </button>
      </div> -->
      <!-- Indentation buttons -->
      <!-- <div class="toolbar-group">
        <button class="btn btn-light sbtn" @click="editor.chain().focus().indent().run()" title="Indent">
          <i class="fa fa-indent"></i>
        </button>
        <button class="btn btn-light sbtn" @click="editor.chain().focus().outdent().run()" title="Outdent">
          <i class="fa fa-outdent"></i>
        </button>
      </div> -->


      <!-- Text & Background Color -->
      <!-- <div class="toolbar-group">
        <button class="btn btn-light sbtn" @click="toggleTextColorPicker" :class="{ 'is-active': isTextColorPickerVisible }" title="Text Color">
          <i class="fa fa-font" style="color: currentColor;"></i>
        </button>
        <button class="btn btn-light sbtn" @click="toggleBackgroundColorPicker" :class="{ 'is-active': isBackgroundColorPickerVisible }" title="Background Color">
          <i class="fa fa-paint-brush" style="background: currentColor;"></i>
        </button>
      </div> -->
    </div>

    <!-- Color Pickers -->
    <!-- <div v-if="isTextColorPickerVisible" class="color-picker">
      <sketch-picker @input="setTextColor" />
    </div>
    <div v-if="isBackgroundColorPickerVisible" class="color-picker">
      <sketch-picker @input="setBackgroundColor" />
    </div> -->

    <editor-content class="editor-content" :editor="editor" />
  </div>
</template>

<script>
import { Editor } from '@tiptap/core'
import { EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import TextAlign from '@tiptap/extension-text-align'
import TextStyle from '@tiptap/extension-text-style'
import Color from '@tiptap/extension-color'
// import Document from '@tiptap/extension-document'
// import Paragraph from '@tiptap/extension-paragraph'
// import Text from '@tiptap/extension-text'
import Underline from '@tiptap/extension-underline'
// import Indent from '@tiptap/extension-indent'
// import { Sketch } from 'vue-color'

export default {
  components: {
    EditorContent,
    // 'sketch-picker': Sketch
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    toolbar: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      editor: null,
      isTextColorPickerVisible: false,
      isBackgroundColorPickerVisible: false
    }
  },
  watch: {
    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value

      // JSON
      // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

      if (isSame) {
        return
      }

      this.editor.commands.setContent(value, false)
    },
  },
  mounted() {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit,
        TextAlign.configure({
          types: ['heading', 'paragraph']
        }),
        TextStyle,
        Color,
        Underline,
        // Indent
      ],
      editorProps: {
        transformPasted: (slice) => {
          const fragment = slice.content
          fragment.descendants((node) => {
            if (node.type.name === 'text' && node.marks.length > 0) {
              node.marks = node.marks.filter(mark => mark.type.name !== 'fontSize')
            }
            return true
          })
          return slice
        }
      },
      onUpdate: () => {
        // HTML
        this.$emit('input', this.editor.getHTML())

        // JSON
        // this.$emit('input', this.editor.getJSON())
      },
    });
    // disabled state
    if (this.disabled) this.editor.setEditable(false);
  },

  beforeDestroy() {
    this.editor.destroy()
  },
  methods: {
    isActive(format, options = {}) {
      return this.editor.isActive(format, options)
    },
    toggleTextColorPicker() {
      this.isTextColorPickerVisible = !this.isTextColorPickerVisible
      this.isBackgroundColorPickerVisible = false
    },
    toggleBackgroundColorPicker() {
      this.isBackgroundColorPickerVisible = !this.isBackgroundColorPickerVisible
      this.isTextColorPickerVisible = false
    },
    setTextColor(color) {
      this.editor.chain().focus().setColor(color.hex).run()
    },
    setBackgroundColor(color) {
      this.editor.chain().focus().setBackgroundColor(color.hex).run()
    }
  },
  beforeDestroy() {
    this.editor.destroy()
  }
}
</script>

<style>
.ProseMirror {
  font-size: 0.85rem !important;
}

.ProseMirror * {
  font-size: 0.85rem !important;
}

.tiptap {
  border: 1px solid #d7d7d7;
  border-radius: 0.1rem;
  padding: 0.5rem 0.75rem;
  background-color: #fff;
  min-height: 150px;
}

.tiptap :first-child {
  margin-top: 0;
  /* List styles */
}

.tiptap ul,
.tiptap ol {
  padding: 0 1rem;
  margin: 1.15rem 1rem 1.15rem 0.4rem;
}

.tiptap ul li p,
.tiptap ol li p {
  margin-top: 0.15em;
  margin-bottom: 0.15em;
  /* Heading styles */
}

.tiptap h1,
.tiptap h2,
.tiptap h3,
.tiptap h4,
.tiptap h5,
.tiptap h6 {
  line-height: 1.1;
  margin-top: 2.5rem;
  text-wrap: pretty;
}

.tiptap h1,
.tiptap h2 {
  margin-top: 3.5rem;
  margin-bottom: 1.5rem;
}

.tiptap h1 {
  font-size: 1.4rem;
}

.tiptap h2 {
  font-size: 1.2rem;
}

.tiptap h3 {
  font-size: 1.1rem;
}

.tiptap h4,
.tiptap h5,
.tiptap h6 {
  font-size: 1rem;
  /* Code and preformatted text styles */
}

.tiptap code {
  background-color: var(--purple-light);
  border-radius: 0.4rem;
  color: var(--black);
  font-size: 0.85rem;
  padding: 0.25em 0.3em;
}

.tiptap pre {
  background: var(--black);
  border-radius: 0.5rem;
  color: var(--white);
  font-family: 'JetBrainsMono', monospace;
  margin: 1.5rem 0;
  padding: 0.75rem 1rem;
}

.tiptap pre code {
  background: none;
  color: inherit;
  font-size: 0.8rem;
  padding: 0;
}

.tiptap blockquote {
  border-left: 3px solid var(--gray-3);
  margin: 1.5rem 0;
  padding-left: 1rem;
}

.tiptap hr {
  border: none;
  border-top: 1px solid var(--gray-2);
  margin: 2rem 0;
}

.toolbar {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #d7d7d7;
  padding: 5px;
  background-color: #fff;
}

.toolbar-group {
  display: flex;
  /* margin-right: 10px; */
  border-right: 1px solid #d7d7d7;
  align-items: center;
}

.toolbar button {
  background: none;
  border: none;
  border-radius: 0;
  padding: 5px 10px;
  margin-left: 1px;
  margin-right: 1px;
  /* margin-right: 5px; */
  /* cursor: pointer; */
}

.toolbar button[disabled] {
  background-color: transparent;
}

.toolbar button.is-active {
  background-color: #ddd;
}

.toolbar button i {
  font-size: 16px;
}

.color-picker {
  position: absolute;
  z-index: 10;
  margin-top: 10px;
}
</style>
