<template>
    <div>
        <div class="fixed-inner-header">
            <div class="container-fluid p-0">
                <TimeSheetMasterList :ermMasterInfo="ermMasterInfo" ></TimeSheetMasterList>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import TimeSheetMasterList from '../../components/blocks/erm/CompanyERMMasterList.vue'
export default {
    name: 'app',
    components: {
        TimeSheetMasterList,
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                showUATMasterList: false
            },
            ermMasterInfo: {
                title: "Task Plan List",
                entity: 'development_plan_master',
                refEntity: 'time_sheet',
                tooltip: 'New Task Plan',
                redirectURLName: 'lantms',
                newEntityMasterURL: 'tmsmas',
                redirectURL: '/tmsmas',
                actionName: 'Time Sheet',
                previewURLName: 'lantmsrep',
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>