<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Technology Skill</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">
                                <div id="scope-app" class="mb-3">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                                                <span v-if="!elements.editMode"><i class="fa fa-plus-circle action-link pr-2" aria-hidden="true"></i>New Technology Skill</span>
                                                <span v-if="elements.editMode">Edit</span> 
                                            </label> 
                                        </b-card-header>
                                        <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="">
                                                <div class="row mb-3 mt-0">
                                                    <div class="col-8">
                                                        <div class="form-group">
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.technologySkill.technology.$error }">
                                                                <b-input-group prepend="Technology" class="my-3">
                                                                    <b-form-input type="text" class="form-control" aria-label="First name"  v-model="technologySkill.technology" placeholder="Enter technology"></b-form-input>
                                                                </b-input-group>
                                                                <div class="required-icon"></div>
                                                            </div>
                                                          <label v-if="$v.technologySkill.technology.$error && !$v.technologySkill.technology.required" class="text-danger">Please enter technology</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="active">Active</label>
                                                            <div class="input-group ">
                                                                 <b-form-checkbox class="custom-toggle" v-model="technologySkill.active" name="check-button" switch>
                                                                 </b-form-checkbox>
                                                            </div>       
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <label for="skillSet">Skill Set</label>
                                                        <b-form-tags input-id="tags-separators" v-model="technologySkill.skillSet" separator="," placeholder="Enter skill set" maxlength="2000" ></b-form-tags>
                                                    </div>                                                                                                
                                                </div>
                                                <button type="submit" @click="saveData" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                <button type="submit" @click="handleCancel" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay>

                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table ref="technologySkillTable" show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :busy="isBusy" :items="technologySkills" @row-selected="onRowSelected" :fields="technologySkillFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template v-slot:cell(active)="data">
                                    <b-form-checkbox class="ml-3" v-model="data.value" @change="activation(data.item)">
                                    </b-form-checkbox>
                                </template>
                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                    <a href="javascript:" title="Delete" @click="deleteTechnologySkill(data.item.id)" v-b-modal.deleteTechnologySkill ><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Technology Skill Found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            <b-pagination v-if="technologySkills.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0 mb-4">
                    <div class="card h-100">
                        <div class="card-body p-3">
                            <b-tabs v-if="!elements.visible && elements.showDetail" content-class="mt-3" lazy>
                                <b-tab title="Details">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label >Technology</label>
                                                <b-input-group :prepend="technologySkillDetail.technologySkillId" class="mb-2">
                                                <b-form-input aria-label="First name" disabled v-model="technologySkillDetail.technology"></b-form-input>
                                                </b-input-group>
                                            </div>
                                        </div>

                                        <div class="col-12">
                                            <div class="form-group">
                                                <label>Skill Set</label>
                                                <textarea disabled v-model="technologySkillDetail.skills" class="form-control" rows="4" required></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="card-body p-0 my-2">
                                                <b-card no-body>
                                                    <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                        <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.rr>
                                                            <label>More Details</label>
                                                        </label>
                                                    </b-card-header>
                                                    <b-collapse id="rr" accordion="my-accordion1" role="tabpanel">
                                                        <b-card-body class="p-1 pb-0">
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <div class="form-group">
                                                                        <label for="preparedBy">Prepared By</label>
                                                                        <input type="text" class="form-control" id="preparedBy" disabled v-model='technologySkillDetail.preparedBy'>
                                                                    </div>
                                                                </div>
                                                                <div class="col-6">
                                                                    <div class="form-group">
                                                                        <label for="createdDt">Created Date</label>
                                                                        <input type="text" class="form-control" id="createdDt" disabled :value="formatDate(technologySkillDetail.createdDt)">
                                                                    </div>
                                                                </div>
                                                                <div class="col-6">
                                                                    <div class="form-group">
                                                                        <label for="lastUpdatedDt">Last Updated Date</label>
                                                                        <input type="text" class="form-control" id="lastUpdatedDt" disabled :value="formatDate(technologySkillDetail.lastUpdatedDt)">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </b-card-body>
                                                    </b-collapse>
                                                </b-card>
                                            </div>
                                        </div>
                                    </div>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <DeleteComponent id="deleteTechnologySkill" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Technology Skill?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    name: 'app',
    data() {
        return {
            showOverlay: false,
            elements: {
                showDetail: false,
                editMode: false,
                visible: false,
            },
            isBusy: false,
            entity:'technology_skill',
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userId: localStorage.getItem("userId"),

            technologySkill: {
                technologySkillId: '',
                vhextAccountId: '',
                projectId: '',
                preparedById: '',
                technology: '',
                skills: '',
                skillSet: [],
                active: true,
                createdDt: '',
                lastUpdatedDt: '',

            },
            technologySkillDetail: {
                technologySkillId: '',
                vhextAccountId: '',
                projectId: '',
                preparedById: '',
                technology: '',
                skills: '',
                skillSet: [],
                active: '',
                createdDt: '',
                lastUpdatedDt: '',

            },
            notEnabled: true,
            users: [],
            technologySkills: [],
            projects: [],
            transProps: {
                // Transition name
                name: 'flip-list'
            },
            technologySkillFields: [
                { key: 'technologySkillId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'technology', label: 'Technology', sortable: true, sortDirection: 'desc' },
                { key: 'skills', label: 'Skills', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],

            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15,20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        }
    },
    mounted() {
        this.getTechnologySkill();
        this.lookupData();
    },
    validations: {
        technologySkill: {
            technology: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(100)
            }
        }
    },
    methods: {
        getTechnologySkill: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/resource/technologyskill/list', data)
                .then((response) => {
                    let result = response.data;
                    this.technologySkills = result.technologySkillList;
                    this.totalRows = this.technologySkills.length;
                    this.$nextTick(() => {
                            this.$refs.technologySkillTable.selectRow(0);
                        })
                    console.log(result);
                }).catch(error => {
                    console.log(error);
                });
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/resource/technologyskill/lookup/all', data)
                .then((response) => {
                    let result = response.data;

                    this.users = result.userLookup;
                    console.log(this.users);
                })

        },
        saveData: function() {
            this.technologySkill.vhextAccountId = this.vhextAccountId,
            this.technologySkill.projectId = this.projectId,
            this.technologySkill.preparedById = this.userId,


            this.technologySkill.skills = this.arrayToString(this.technologySkill.skillSet);
            console.log(this.technologySkill);
            this.$v.technologySkill.$touch();
            if (!this.$v.technologySkill.$invalid) {
                if (this.elements.editMode) {
                    this.updateTechnologySkill();
                } else {
                    this.saveTechnologySkill();
                }
            }
        },
        saveTechnologySkill: function() {
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/technologyskill/add', this.technologySkill)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                            
                        });
                        this.getTechnologySkill();
                        this.clearData();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        updateTechnologySkill: function() {
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/technologyskill/update', this.technologySkill)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.elements.editMode = false;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getTechnologySkill();
                        this.handleCancel();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        deleteTechnologySkill: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/resource/technologyskill/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getTechnologySkill();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        handleCancel: function() {
            this.elements.editMode = false;
            this.elements.showDetail = false;
            this.elements.visible = false;
            this.clearData();
        },
        clearData: function() {
            this.elements.visible = false;
            this.elements.editMode = false;
            this.technologySkill.active = true;
            this.technologySkill.skills = '';
            this.technologySkill.skillSet = [];
            this.technologySkill.preparedById = '';
            this.technologySkill.technology = '';
            this.technologySkill.id = '';
            this.$v.technologySkill.$reset();
        },
        showEdit: function(data) {
            this.elements.visible = true;
            this.elements.editMode = true;
            this.technologySkill.technologySkillId = data.technologySkillId;
            this.technologySkill.preparedById = data.preparedById;
            this.technologySkill.technology = data.technology;
            this.technologySkill.skillSet = data.skills.split(',');
            this.technologySkill.active = data.active;
            this.technologySkill.preparedDt = data.preparedDt;
            this.technologySkill.id = data.id;
        },
        onRowSelected: function(items) {
            if (items != null){
                let data = items[0];
                this.elements.showDetail = true;
                this.elements.visible = false;
                this.technologySkillDetail.id = data.id;
                this.technologySkillDetail.technologySkillId = data.technologySkillId.toString();
                this.technologySkillDetail.technology = data.technology;
                this.technologySkillDetail.skills = data.skills;
                this.technologySkillDetail.preparedBy = data.preparedBy;
                this.technologySkillDetail.createdDt = data.createdDt;
                this.technologySkillDetail.lastUpdatedDt = data.lastUpdatedDt;
            }
        },
        activation: function(item) {
            this.isBusy = true;
            console.log(item.active,item.id,item.preparedById);
            if (item.active == true) {

                this.technologySkill.id = item.id;
                this.technologySkill.entity = this.entity;
                this.technologySkill.active = 0;
                this.updateActivationState();

            } else {
                this.technologySkill.id = item.id;
                this.technologySkill.entity = this.entity;
                this.technologySkill.active = 1;
                this.updateActivationState();
                }

        },

        updateActivationState: function() {
            
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/activationstate/update', this.technologySkill)
                .then((response) => {
                    this.isBusy = false;
                    let result = response;
                    console.log(result);
                    if (result.status == 200) {
                        iziToast.success({
                            title: 'Updated',
                            position: 'topCenter'
                        });
                    }
                    this.getTechnologySkill();
                })
                .catch((error)=> {
                    this.isBusy = false;
                    console.log(error);
                    this.getTechnologySkill();
                })
        },

        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        arrayToString: function (arrayData) {
            if (arrayData != null && arrayData.length > 0) {
                return arrayData.toString();
            }
            return "";
        },



    }
}
</script>